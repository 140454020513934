@import '@/styles/variables';

.loader {
  @include loader;
}

.settings {
  &__content {
    --container-paddings: 40px;
    --page-head-content-height: 440px;
    --screen-height: calc(100vh - 30px);

    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, var(--max-column-width, 1fr)));
    grid-gap: 20px;
    min-height: calc(var(--screen-height) - var(--page-head-content-height) - var(--container-paddings));
    padding-right: 0;


    &::after {
      position: absolute;
      top: 20px;
      right: 0;
      bottom: 20px;
      width: 2px;
      content: '';
      background: var(--color-white);
    }
  }
}
