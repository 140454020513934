.reagents-autocomplete {
  background-color: var(--color-white);
  border: 1px solid var(--light-color-gray-10);
  border-radius: 6px;

  &__selector {
    width: var(--reagent-column-width);
    text-align: left;
  }

  &__data-text {
    width: var(--reagent-column-width);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
