.input {
  --input-bg-color: transparent;
  --input-border-color: var(--theme-input-border-color, var(--color-gray));
  --input-border-color-focus: var(--theme-input-border-color-focus, var(--color-white));
  --input-border-color-disabled: var(--theme-input-border-color-disabled, var(--color-black-10));
  --input-color: var(--input-color, var(--color-white));
  --input-color-disabled: var(--color-gray-10);
  --input-color-following-text: var(--color-gray-20);
  --input-color-following-text-disabled: var(--color-gray-60);
  --input-bg-colour-disabled: var(--color-black-10);
  --input-border-width: 2px;
  --input-padding: 0 12px;
  --input-font-size: 14px;
  --input-font-weight: 500;
  --input-height: 40px;

  position: relative;
  width: 100%;
  height: 100%;

  &_white {
    --input-border-color-disabled: var(--light-color-gray-10);
    --input-bg-colour-disabled: var(--color-white-20);
  }

  &__input {
    width: 100%;
    min-width: 80px;
    max-width: 100%;
    height: var(--input-height);
    padding: var(--input-padding);
    overflow: hidden;
    font-size: var(--input-font-size);
    font-style: normal;
    font-weight: var(--input-font-weight);
    font-stretch: normal;
    line-height: 1;
    color: var(--input-color);
    text-align: left;
    text-overflow: ellipsis;
    letter-spacing: .5px;
    white-space: nowrap;
    background-color: var(--input-bg-color);
    border: var(--input-border-width) solid var(--input-border-color);
    border-radius: 10px;
    outline: none;

    &:focus {
      --input-border-color: var(--input-border-color-focus);
    }

    &_disabled,
    &:disabled {
      --input-border-color: var(--input-border-color-disabled);
      color: var(--color-gray-20);
      cursor: not-allowed;
    }
  }

  &__input_following_text {
    padding: 10px 24px 10px 12px;
  }

  &[data-following-text]::after {
    position: absolute;
    top: 50%;
    right: 10px;
    display: block;
    font-size: var(--input-font-size);
    color: var(--input-color-following-text);
    content: attr(data-following-text);
    transform: translate(-50%, -50%);
  }

  &__disabled[data-following-text]::after {
    color: var(--input-color-following-text-disabled);
    cursor: not-allowed;
  }

  &__error {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 14px;
    color: var(--color-red-error);
  }
}

.input__input.bg-light {
  &:focus {
    --input-border-color: var(--input-border-color-focus);
  }
}
