.toggleable-block {
  --sidebar-column-width: var(--sidebar-width);
  --gap: 20px;
  --main-column-width: calc(100% - var(--sidebar-column-width) - var(--gap));
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__wrap {
    display: flex;
    flex-grow: 1;
    gap: 20px;
  }

  &_open {
    --sidebar-column-width: 20px;
    --sidebar-content-opacity: 0;
  }

  &__main {
    display: flex;
    flex-shrink: 0;
    width: var(--main-column-width);
    transition: width .3s ease-out;
  }

  &__sidebar {
    --vertical-offset: 20px;
    --bottom-screen-offset: 10px;
    --vertical-limit: calc(var(--vertical-offset) * 2 + var(--bottom-screen-offset) + var(--header-height));
    position: sticky;
    top: 70px;
    flex-shrink: 0;
    align-self: flex-start;
    width: var(--sidebar-column-width);
    height: min(calc(100vh - var(--vertical-limit)), 100%);
    padding: 15px 0;
    background-color: var(--color-white);
    border-radius: 20px;
    transform: translateX(0);
  }

  &_open &__sidebar {
    transition: width .3s 0s ease-out;
  }

  &__sidebar-content {
    height: 100%;
    opacity: var(--sidebar-content-opacity, 1);
    transition: opacity .3s ease-out;
  }

  &__trigger-track {
    --trigger-bg-color: var(--color-white);
    --trigger-border-color: var(--color-white);

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 16px;
    cursor: pointer;
    outline: none;
    transform: translateX(-50%);

    &:hover {
      --trigger-border-color: var(--color-yellow-40);
    }

    &:focus-visible {
      --trigger-bg-color: var(--color-yellow-40);
    }
  }

  &__trigger {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 50px;
    background-color: var(--trigger-bg-color);
    border: 2px solid var(--trigger-border-color, var(--color-white));
    border-radius: 15px;
    box-shadow: 0 0 5px var(--color-white-20);
    transition: background-color .3s ease-out, border-color .3s ease-out;
    transform: translate(-50%, -50%);

    &:hover {
      --trigger-bg-color: var(--color-yellow-40);
    }
  }

  &__trigger-icon {
    color: var(--color-gray-70);
    transition: transform .3s ease-out;
  }

  &_open &__trigger-icon {
    transform: rotate(180deg);
  }
}
