@import '@/styles/tabs';

@include tabs;

.analytics {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__title {
    font-size: 50px;
    font-weight: normal;
    line-height: 1.2;
    color: var(--color-black);
    letter-spacing: -0.75px;
  }

  &__range {
    min-width: 116px;
    max-width: 116px;
  }

  &__range-slider-value {
    display: none;
  }

  &__range-info {
    margin: 0;
  }

  &__tabs-container {
    position: relative;
    left: var(--anchor-content-left, 0);
    display: flex;
    transition: left 0.3s ease-out, transform 0.2s 0.1s ease-out;
    will-change: left;
    transform: var(--anchor-content-translate, translateX(0));
  }

  :global(#images-scroll-area) {
    --min-gap: 1px;

    max-height: calc(100% - var(--min-gap));
  }
}

.analytics__tabs {
  height: 100%;
}
