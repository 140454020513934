.chart-modebar-button {
  display: grid;
  place-content: center;
  width: 45px;
  height: 45px;
  padding: 0;
  color: var(--color-black);
  cursor: pointer;
  background-color: var(--color-white-20);
  border-radius: 10px;
  transition: color .3s ease-out;

  &:disabled {
    color: var(--light-color-gray-15);
  }

  &_active,
  &:hover:enabled {
    background-color: var(--color-yellow-10);
    border-color: var(--color-yellow-10);
  }

  &__icon {
    height: max-content;
  }
}
