@import '@/styles/roundButton';

.details-button {
  @include round-button;

  &_open {
    color: var(--color-white);
    background-color: var(--color-black-5);
    transform: rotate(-180deg);

    &:hover {
      background-color: var(--color-black-5);
    }
  }
}
