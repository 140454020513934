.table {
  --table-background-color: #f1f1f1;
  $root: &;

  width: 100%;
  margin-top: 10px;
  overflow-x: auto;
  white-space: nowrap;
  border-collapse: collapse;
  border-block: 1px solid var(--light-color-gray-10);

  &__row {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      content: '';
      background-color: var(--light-color-gray-10);
    }

    &:nth-child(even) {
      background-color: var(--table-background-color);
    }

    &_no-underline {
      &::after {
        display: none;
      }
    }
  }

  &__head {
    background-color: var(--table-background-color);
  }

  &__heading, &__data-element {
    padding: 10px;
    border-left: 1px solid var(--light-color-gray-10);

    &:last-child {
      border-right: 1px solid var(--light-color-gray-10);
    }
  }

  &__heading {
    padding-top: 10px;
    text-transform: capitalize;
  }
}
