.project-list {
  position: relative;
  min-height: 100%;

  &__search {
    min-width: 350px;
  }

  &__button {
    width: 45px;
    height: 45px;
    padding: 0;
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    font-size: 50px;
    color: var(--color-gray-80);
  }

  &__cards-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  &__list {
    --grid-layout: 3fr 40px repeat(2, 1fr) repeat(2, 100px) 35px;
    background-color: var(--color-white);
    border-radius: 20px;
  }

  &__list-heading {
    display: grid;
    grid-template-columns: var(--grid-layout);
    gap: 20px;
    padding: 20px;
  }

  &__list-title {
    font-size: 13px;
    font-weight: 500;
    color: var(--light-gray);
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.project-list-header {
  margin-bottom: 40px;

  &__title-count {
    padding: 5px 12px;
    margin-left: 6px;
    font-size: 17px;
    vertical-align: middle;
    border: 1px solid var(--color-gray-80);
    border-radius: 23px;
  }

  &__actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__new-project-button {
    --button-font-size: 17px;

    width: fit-content;
    padding: 0 40px;
    font-weight: normal;
  }
}
