.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  //background-color: var(--color-black-10);

  &__title {
    font-size: 30px;
    font-weight: normal;
    line-height: normal;
    color: var(--color-black);
    letter-spacing: -.43px;
  }
}
