.gates-accordion-wrapper {
  --horizontal-padding: 10px;
  --settings-button-width: 13px;

  &__heading {
    padding-right: calc(var(--horizontal-padding) + var(--settings-button-width));
    padding-left: var(--horizontal-padding);

    &::after {
      display: none;
    }
  }

  .gates-accordion-wrapper__children {
    position: relative;
  }
}
