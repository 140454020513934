.info-button {
  --tooltip-color: var(--light-gray);
  --tooltip-hover-color: var(--light-gray);
  --info-button-size: 26px;

  width: var(--info-button-size);
  height: var(--info-button-size);
  padding: 0;
  font-family: SFMono, Arial, Helvetica, sans-serif;
  font-size: calc(var(--info-button-size) / 2);
  font-weight: bold;
  color: var(--tooltip-color);
  border: solid 2px var(--tooltip-color);
  border-radius: 50%;

  &:hover:enabled {
    color: var(--color-white-10);
    background-color: var(--tooltip-hover-color);
    border-color: var(--tooltip-hover-color);
  }
}
