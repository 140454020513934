@import '@/styles/variables';

.popover-content {
  @include box-shadow;

  width: 300px;
  height: 80vh;
  padding: 20px;
  overflow: hidden;
  background-color: var(--color-white);
  border-radius: 10px;
}

.list-wrapper {
  height: calc(100% - 60px);
  padding-bottom: 10px;
  overflow-y: auto;
}

.list {
  overflow: scroll;
  padding-inline: 10px;

  &__item {
    padding-block: 10px;
    border-bottom: solid 1px var(--icons-border-color);
    
    &:first-child {
      padding-top: 0;
    }
  }

  &__item-checkbox {
    height: auto;
  }
}

.buttons {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  padding-top: 20px;
  border-top: solid 1px var(--color-black);

  &__item {
    width: 100px;
    height: 40px;
    font-size: 20px;
    font-weight: normal;
    color: var(--color-black);
    border: none;
  }
}

.close-btn {
  width: 60px;
  color: var(--color-black);
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
  padding-inline: 0;

  &:hover:enabled,
  &:active:enabled {
    color: var(--color-black);
    background-color: var(--color-white);
  }
}

.overlay-btn {
  box-shadow: inset 0 0 0 1px var(--color-black);
  transition: box-shadow .3s ease-out, background-color .3s ease-out;

  &:hover:enabled,
  &:active:enabled {
    color: var(--color-black);
    background-color: var(--color-white);
    box-shadow: inset 0 0 0 2px var(--color-black);
  }
}

.popover-btn {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: 35px;
  font-size: 17px;
  font-weight: normal;
  color: var(--color-black-20);
  text-align: center;
  background-color: var(--color-white);
  border: 1px solid var(--icons-border-color);
  border-radius: 10px;
  padding-inline: 10px;

  &:hover:enabled,
  &:active:enabled {
    color: var(--color-black);
    background-color: var(--color-white);
    border-color: var(--icons-border-color);
  }
}
