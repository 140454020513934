@import '@/styles/variables';

.filters-modal {
  width: 475px;
  padding: 0;

  &__header {
    position: relative;
    padding: 20px;
    font-weight: 600;
    border-bottom: 1px solid var(--light-color-gray-10);
  }

  &__content {
    padding: 0;
  }
}

.section {
  padding: 20px;

  & + & {
    border-top: 1px solid var(--light-color-gray-10);
  }

  &_highlight-objects {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.channel {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header-title {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
  }

  &__header-title-color {
    --color: var(--color-white-20);

    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: var(--color);
    border-radius: 50%;
  }


  &__header-title-lut {
    display: inline-block;
  }

  &__header-title-lut-image {
    width: 36px;
    height: 18px;
    border-radius: 9px;
  }


  &__controls-container {
    display: flex;
    gap: 10px;
  }

  &__button {
    --toggle-color-hover: var(--color-black);
    --toggle-color: var(--color-gray-70);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 0;
    color: var(--toggle-color);
    border-radius: 10px;
    transition: color .3s ease-out;

    &:hover {
      color: var(--toggle-color-hover);
    }

    & > svg {
      width: 22px;
      height: 22px;
    }
  }

  &_disabled &__visibility-button {
    padding-bottom: 2px;
  }

  &__controls {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-top: 12px;

    &-range {
      flex-grow: 1;
      width: 50% !important;
      padding-right: 20px;
      border-right: 1px solid var(--color-white-5);

      &-label {
        display: none;
      }

      &-input {
        position: relative;
        width: calc(100% - 36px) !important;
        margin: 0 auto;

        &::before {
          position: absolute;
          left: -16px;
          width: 18px;
          height: 2px;
          content: '';
          background-color: #d8d8d8;
        }

        &::after {
          position: absolute;
          right: -16px;
          width: 18px;
          height: 2px;
          content: '';
          background-color: #d8d8d8;
        }

        & > .rc-slider-rail,
        & > .rc-slider-track {
          height: 2px !important;
        }

        & > .rc-slider-handle {
          width: 18px;
          height: 18px;
        }
      }
    }

    &-input {
      & input {
        width: 100%;
        font-size: 17px;
        color: #404040;
        border-color: #d8d8d8 !important;
      }
    }

    &-input + &-input {
      margin-left: 10px;
    }

    &-color-picker {
      flex-grow: 1;
      width: 50%;
      padding-left: 20px;
    }
  }

  &_disabled {
    .channel__header-title {
      color: var(--color-white-5);
    }

    .channel__header-title-color {
      background-color: var(--color-white-5) !important;
    }
  }
}
