@import '@/styles/dashboard';

.dashboard {
  @include dashboard;
}

.header-actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.header-action {
  --button-font-size: 17px;

  width: fit-content;
  padding: 0 40px;
  font-weight: normal;
}
