.navigator {
  --separator-color: var(--light-color-gray-10);
  --settings-width: 365px;

  display: grid;
  grid-template-areas:
    'viewer settings'
    'timeline timeline';
  grid-template-rows: 1fr 85px;
  grid-template-columns: 1fr var(--settings-width);
  width: 100%;
  height: 100%;
  overflow: hidden;

  &_expand {
    --separator-color: transparent;

    grid-template-rows: 1fr 1px;
    grid-template-columns: 1fr 1px;
  }

  &__viewer {
    flex-grow: 1;
    grid-area: viewer;
    padding: 20px;
  }

  &__settings {
    position: relative;
    grid-area: settings;
    overflow: hidden;

    &::before {
      position: absolute;
      top: 20px;
      bottom: 20px;
      left: 0;
      width: 0;
      content: '';
      border-left: 1px solid var(--separator-color);
      transition: .3s border-color;
    }
  }

  &__timeline {
    position: relative;
    grid-area: timeline;
    overflow: hidden;

    &::before {
      position: absolute;
      top: 0;
      right: 20px;
      left: 20px;
      height: 0;
      content: '';
      border-top: 1px solid var(--separator-color);
      transition: .3s border-color;
    }
  }
}

.navigator-modal {
  --separator-color: var(--light-color-gray-10);

  padding: 0;
  overflow: hidden;

  &__header {
    position: relative;
    padding:  0 20px 0 0;

    &::before {
      position: absolute;
      right: 20px;
      bottom: 0;
      left: 20px;
      height: 0;
      content: '';
      border-bottom: 1px solid var(--separator-color);
    }
  }

  &__header-wrap {
    display: flex;
    padding: 0;
  }

  &__header-block {
    position: relative;
    padding: 20px;

    &::before {
      position: absolute;
      top: 20px;
      right: 0;
      bottom: 20px;
      width: 0;
      content: '';
      border-right: 1px solid var(--separator-color);
    }

    &:last-child::before {
      opacity: 0;
    }
  }

  &__content {
    padding: 0;
  }
}
