.bead-card {
  --selected-card-background-color: var(--color-blue-60);
  --marker-card-color: var(--color-blue-60);
  --background-color: var(--selected-card-background-color);
  --card-height: 70px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: var(--card-height);
  padding: 10px 20px 10px 30px;
  cursor: pointer;
  background-color: var(--background-color);
  border-radius: 10px;
  opacity: 1;
  transition: opacity, background-color .2s ease-out;

  &_big-gap {
    padding-left: 45px;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 20px;
    width: 10px;
    height: 10px;
    content: '';
    background-color: var(--marker-card-color);
    transform: translateY(-50%);
  }

  &_disabled {
    --background-color: var(--color-white-15);
    color: var(--light-color-gray-15);
    cursor: not-allowed;
  }

  &_selected {
    --background-color: var(--color-yellow-40);
  }

  &__content-wrapper {
    --wrapper-display: block;
    display: var(--wrapper-display);
    width: 95%;

    &_inline {
      --wrapper-display: flex;
      justify-content: space-between;
    }
  }

  &__popover-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
  }

  &__dots {
    width: fit-content;
    padding: 8px 8px 5px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color .2s ease-out;

    &:hover {
      background-color: var(--color-white-15);
    }
  }

  &__title {
    display: block;
    width: 100%;
    overflow: hidden;
    word-wrap: break-word;
  }

  &__description {
    display: flex;
    column-gap: 5px;
  }
}

.cages {
  display: flex;
  column-gap: 5px;

  &__count {
    font-weight: bold;
  }
}
