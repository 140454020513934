.popover {
  max-height: 200px;
  overflow-y: auto;

  &__option-label {
    margin-right: 4px;
  }

  &__option-part {
    &:not(:first-child)::before {
      content: '; ';
    }
  }
}
