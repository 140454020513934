.react-datepicker {
  overflow: hidden;
  font-family: 'Inter Display', Arial, sans-serif;
  font-size: 14px;
  border-radius: 20px;
}

.react-datepicker__header  {
  background-color: var(--color-white);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
  color: var(--color-white);
  background-color: var(--color-black);

  &:not([aria-disabled="true"]):hover {
    background-color: var(--color-black);
  }
}
