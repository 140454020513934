@import '@/styles/variables';

.hovertemplate {
  --offset-left: 0;
  --offset-top: 0;

  @include box-shadow;

  position: fixed;
  top: var(--offset-top);
  left: calc(var(--offset-left));
  z-index: var(--z-plot-point-popover);
  width: max-content;
  padding: 5px;
  font-size: 12px;
  pointer-events: none;
  background-color: var(--color-white);
  border: solid 1px var(--shadow-color);
}
