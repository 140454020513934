.project-card {
  --label-color: #999;
  --icon-opacity: 0;
  display: flex;
  flex-direction: column;
  min-height: 280px;
  cursor: pointer;

  &:hover {
    --icon-opacity: 1;
  }

  &__label {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 50%;
    height: 30px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    color: var(--label-color);
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: var(--color-white);
    border-top-left-radius: 20px;
    border-top-right-radius: 13px;
    padding-inline: 20px;


    &::before {
      position: absolute;
      bottom: 0;
      left: 100%;
      width: 0;
      height: 0;
      content: '';
      border-top: 20px solid transparent;
      border-left: 12px solid var(--color-white);
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--color-white);
    border-radius: 0 20px 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
  }

  &__title {
    padding: 13px 20px 6px;
    font-size: 25px;
    line-height: 1.2;
  }

  &__date {
    padding-bottom: 4px;
    font-size: 17px;
    padding-inline: 20px;
  }

  &__member-data {
    display: flex;
    gap: 28px;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
  }

  &__member-full-name {
    display: flex;
    flex-shrink: 0;
    gap: 8px;
    align-items: center;
  }


  &__experiments {
    position: relative;
    padding: 14px 20px 15px;

    &::before {
      position: absolute;
      top: 0;
      right: 20px;
      left: 20px;
      content: '';
      border-bottom: 1px solid var(--color-black-20);
    }
  }

  &__experiments-number {
    padding-bottom: 6px;
    font-size: 35px;
    line-height: 1;
    letter-spacing: -.25px;
  }

  &__experiments-text {
    font-size: 17px;
  }

  &__experiments-icon-wrap {
    position: absolute;
    right: 20px;
    bottom: 20px;
    opacity: var(--icon-opacity);
    transition: opacity .3s ease-out;
  }
}
