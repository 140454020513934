@keyframes loading {
  0% {
    right: 100%;
    left: 0;
  }

  50% {
    right: 0;
    left: 0;
  }

  100% {
    right: 0;
    left: 100%;
  }
}

.line-loader {
  --main-color: var(--light-color-gray-10);
  --loading-gradient-color-1: var(--color-purple);
  --loading-gradient-color-2: #4a00e0;
  position: relative;
  width: 100%;
  height: 1px;
  background-color: var(--main-color);

  &::after {
    position: absolute;
    right: 100%;
    left: 0;
    display: block;
    height: 100%;
    content: '';
    background: linear-gradient(270deg, var(--loading-gradient-color-1) 0%, var(--loading-gradient-color-2) 100%);
    opacity: 0;
    transition: opacity .3s ease-out;
    animation: loading 3s ease infinite;
    animation-play-state: paused;
  }

  &_animate {
    &::after {
      opacity: 1;
      animation-play-state: running;
    }
  }
}
