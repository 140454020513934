@import '@/styles/variables';

.editable-title {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-end;

  &__btn {
    width: 45px;
    height: 45px;
    padding: 0;
    color: var(--color-black);
    cursor: pointer;
    background-color: var(--color-white);
  }

  &__title {
    position: relative;
    z-index: 1;
    min-width: 250px;

    &:focus-visible {
      outline: none;

      &::before {
        background-color: var(--color-white);
        outline: 2px solid var(--focus-color);
        opacity: 1;
      }
    }

    &::before {
      position: absolute;
      top: -5px;
      left: -5px;
      z-index: -1;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      pointer-events: none;
      content: '';
      background-color: var(--color-white);
      border-radius: 10px;
      outline: 1px dashed var(--light-color-gray-10);     
      opacity: 0;
      transition: opacity .3s ease-out;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-self: flex-end;
    width: 95px;

    &:hover ~ .editable-title__title::before {
      opacity: 1;
    }
  }
}
