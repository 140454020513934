.control-panel {
  --sticky-content-left: 0;
  --sticky-content-translate: translateX(0);
  --reveal-content-left: -100%;
  --reveal-content-opacity: 0;

  position: sticky;
  top: 0;
  z-index: var(--z-header);
  margin-bottom: 20px;
  background-color: var(--color-white-20);

  &_stucked {
    --sticky-content-left: 50%;
    --sticky-content-translate: translateX(-50%);
    --reveal-content-left: 0;
    --reveal-content-opacity: 1;
  }

  &__content {
    position: relative;
    display: flex;
    gap: 20px;
    align-items: center;
    height: var(--header-height);

    &_small {
      height: var(--header-height-sm);
    }
  }

  &__sticky-content {
    position: relative;
    left: var(--sticky-content-left);
    display: flex;
    justify-content: center;
    transition: left .3s ease-out, transform .3s ease-out;
    transform: var(--sticky-content-translate);
  }

  &__sticky-reveal {
    position: absolute;
    top: 50%;
    left: var(--reveal-content-left);
    opacity: var(--reveal-content-opacity);
    transition: left .3s ease-out, opacity .3s ease-out .15s;
    transform: translateY(-50%);
  }

  &__filter-list {
    display: flex;
    flex-shrink: 0;
    gap: 20px;
    align-items: center;
  }

  &__actions {
    &_right {
      position: relative;
      display: flex;
      gap: 5px;
      align-items: center;
      margin-left: auto;
    }
  }

  &__line-loader {
    z-index: -1;
  }
}
