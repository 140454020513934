.switch-input {
  --switch-height: 32px;
  --switch-width: 212px;
  --label-width: calc(var(--switch-width) / 2);
  --font-size: 17px;
  --line-height: 28px;

  &__input {
    position: absolute;
    opacity: 0;
  }

  &__inner {
    --cursor: pointer;
    --opacity: 1;

    position: relative;
    width: var(--switch-width);
    height: var(--switch-height);
    font-size: var(--font-size);
    font-weight: 500;
    cursor: var(--cursor);
    background-color: var(--light-color-gray-10);
    border-radius: 26px;
    opacity: var(--opacity);

    &::before,
    &::after {
      position: absolute;
      width: var(--label-width);
      height: var(--switch-height);
      line-height: var(--line-height);
      text-align: center;
      transition: all .5s ease;
    }

    &::before {
      right: 0;
      color: var(--color-black-5);
      content: attr(data-on);
    }

    &::after {
      left: 0;
      color: var(--color-white);
      content: attr(data-off);
      background-color: var(--color-black-5);
      border-radius: 26px;
    }

    &_checked {
      &::before {
        right: var(--label-width);
        content: attr(data-off);
        transition: all .5s ease;
      }

      &::after {
        left: var(--label-width);
        content: attr(data-on);
        transition: all .5s ease;
      }
    }

    &_small {
      --switch-height: 20px;
      --switch-width: 160px;
      --label-width: calc(var(--switch-width) / 2);
      --font-size: 14px;
      --line-height: normal;
    }

    &_disabled {
      --cursor: not-allowed;
      --opacity: .6;
    }
  }
}
