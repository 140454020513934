.objects-on-canvas {
  &__tooltip {
    position: fixed;
    z-index: 1001;
    padding: 8px;
    font-size: 12px;
    background-color: color-mix(in srgb, var(--color-white-10) 90%, transparent);
    border-radius: 10px;
    transform: translateY(-15px);
  }
}
