.timeline {
  --time-border-color: #d8d8d8;
  --time-border-color-hover: #4c4c4c;
  --time-bg-color-current: #fff575;
  --disabled-bg-color: var(--light-color-gray-20);

  display: flex;

  &__controls {
    position: relative;
    display: flex;
    padding: 20px;

    &::before {
      position: absolute;
      top: 20px;
      right: 0;
      bottom: 20px;
      width: 0;
      content: '';
      border-right: 1px solid var(--separator-color);
    }
  }

  &__order-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 45px;
    padding: 0;
    color: var(--color-gray-70);
    border: 2px solid var(--time-border-color);
    transition: border-color 0.3s ease-out, color 0.3s ease-out;

    &_prev {
      border-right-width: 1px;
      border-radius: 10px 0 0 10px;
    }

    &_next {
      border-left-width: 1px;
      border-radius: 0 10px 10px 0;
    }

    &:hover:enabled {
      --time-border-color: var(--time-border-color-hover);
      color: var(--time-border-color-hover);
    }

    &:disabled {
      color: var(--time-border-color);
      background-color: var(--disabled-bg-color);
    }

    & > svg {
      width: 8px;
      height: 12px;
    }
  }

  &__list {
    display: flex;
    gap: 20px;
    padding: 20px;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__time {
    min-height: 45px;
    padding: 4px;
    padding-inline: 10px;
    font-size: 17px;
    line-height: 1;
    color: var(--time-border-color);
    border: 2px solid var(--time-border-color);
    border-radius: 10px;
    transition: color 0.3s ease-out, background-color 0.3s ease-out, border-color 0.3s ease-out;

    &:hover {
      --time-border-color: var(--time-border-color-hover);
    }

    &._has-current-lane {
      color: var(--color-black);
    }

    &._active {
      background-color: var(--time-bg-color-current);
      border: 2px solid var(--time-bg-color-current);
    }

    small {
      display: block;
      white-space: nowrap;
    }
  }
}
