@import '@/styles/experimentDataControlButton';

.table-wrapper {
  --table-offset: min(256px, 5vw);

  width: calc(100% - var(--table-offset));
  padding: 15px 10px 5px;
  margin-bottom: 20px;
  margin-left: auto;
  background-color: var(--color-white-20);
  border-radius: 20px;

  &_minimal {
    --table-offset: 0;
  }
}

.table-scroll {
  width: 100%;
  padding-bottom: 15px;
  overflow-x: auto;
}

.table {
  position: relative;
  width: max-content;
  min-width: 100%;
  border-collapse: collapse;

  &__head-item {
    padding: 0 10px 10px;
    font-weight: 500;

    &_fit-content {
      width: 1%;
    }

    &_center {
      text-align: center;
    }
  }

  &__no-data {
    padding: 15px 0;
  }

  &__head {
    position: relative;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.3;
    color: var(--light-gray);
    text-transform: uppercase;
    letter-spacing: 1px;

    &::before {
      position: absolute;
      right: 10px;
      bottom: 0;
      left: 10px;
      height: 1px;
      content: '';
      background-color: var(--light-color-gray-10);
      opacity: 1;
      transition: opacity .3s ease-out;
    }
  }

  &__row {
    align-items: center;
  }
}

.channel-details-table {
  --time-column-width: 120px;
  --channel-column-width: 120px;
  --assay-column-width: 235px;
  --marker-column-width: 270px;
  --reagent-column-width: 270px;

  &__head-item {
    &_time {
      min-width: var(--time-column-width);
    }

    &_channel {
      min-width: var(--channel-column-width);
    }

    &_assay {
      min-width: var(--assay-column-width);
    }

    &_marker {
      min-width: var(--marker-column-width);
    }

    &_reagent {
      min-width: var(--reagent-column-width);
    }
  }
}

.detailed-row {
  position: relative;
  cursor: pointer;

  &:hover {
    --corner-radius: 10px;
    --row-bg-color: var(--color-white);
    --row-line-opacity: 0;
  }

  &__channel-mark {
    --bg-color: var(--color-bf);

    display: inline-block;
    width: 100%;
    padding: 6px 10px;
    background-color: var(--bg-color);
    border: 1px solid var(--light-color-gray-5);
    border-radius: 10px;
  }

  &__data-text {
    display: inline-block;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  &__cell {
    height: 56px;
    padding: 0 10px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: var(--row-bg-color, transparent);
    transition: background-color .3s ease-out;

    &_name {
      text-align: left;
    }

    &_time {
      small {
        display: block;
        line-height: 1;
      }
    }

    &_control-items {
      position: sticky;
      right: 0;
    }

    &_line-holder {
      position: static;

      &::after,
      &::before {
        position: absolute;
        right: 10px;
        left: 10px;
        height: 1px;
        content: '';
        transition: opacity .3s ease-out;
      }

      &::after {
        bottom: 0;
        background-color: var(--light-color-gray-10);
        opacity: var(--row-line-opacity, 1);
      }

      &::before {
        top: -1px;
        background-color: var(--color-white-20);
        opacity: calc(1 - var(--row-line-opacity, 1));
      }
    }

    &:first-child {
      border-top-left-radius: var(--corner-radius, 0);
      border-bottom-left-radius: var(--corner-radius, 0);
    }

    &:last-child {
      border-top-right-radius: var(--corner-radius, 0);
      border-bottom-right-radius: var(--corner-radius, 0);
    }
  }

  &:last-child &__cell_line-holder::after {
    opacity: 0;
  }

  &__control-items {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__checkbox {
    width: 20px;

    &_active {
      position: relative;
      z-index: 1;

      &::before {
        position: absolute;
        left: -5px;
        z-index: -1;
        width: 30px;
        height: 30px;
        pointer-events: none;
        content: '';
        background-color: var(--light-color-gray-10);
        border-radius: 10px;
      }
    }
  }
}

.control-item {
  @include experiment-data-control-button
}

.label-group {
  display: flex;
  gap: 20px;
  align-items: center;

  &__item {
    position: relative;

    &:not(:first-child) {
      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -10px;
        width: 1px;
        content: '';
        background-color: var(--light-color-gray-10);
      }
    }
  }
}
