.lane {
  --label-color: var(--color-black);
  --thumb-color: var(--label-color);
  --lane-bg-color: #f0f0f0;

  position: relative;
  padding: 0;
  margin-left: -10px;
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;

  &__wrap {
    height: 28px;
    pointer-events: none;
  }

  &_active {
    cursor: default;
  }

  &_active,
  &:hover {
    --lane-bg-color: var(--color-yellow-10);
  }

  &_active &__wrap {
    pointer-events: all;
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 10px;
    font-size: 16px;
    font-weight: 500;
    color: var(--label-color);
    transform: translateY(-50%);
  }

  &__track-wrap {
    position: absolute;
    top: 5px;
    right: 15px;
    bottom: 0;
    left: 40px;
    height: 18px;
    background-color: var(--lane-bg-color);
    border-radius: 4px;
  }

  &__track {
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition: none;
  }

  &__thumb {
    position: absolute;
    top: calc(50% - 8px);
    right: calc(var(--right, 0) * 1px);
    left: calc(var(--left, 0) * 1px);
    height: 16px;
    color: var(--thumb-color);
    cursor: pointer;
    will-change: left, right;
  }

  &_active &__track {
    pointer-events: all;
    opacity: 1;
    transition: opacity .5s 1.5s ease-out;
  }

  &:hover &__thumb {
    transition: none;
  }

  &__thumb-bracket {
    position: absolute;
    top: 0;
    height: 100%;

    &_left {
      left: 0;
    }

    &_right {
      right: 0;
      transform: scaleX(-1);
    }
  }
}
