.invalid-browser {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-color: var(--color-white-20);

  &__list {
    display: flex;
    gap: 20px;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    width: 100px;
    transition: filter .3s ease-out;
  }

  &__elem:hover &__image {
    filter: drop-shadow(0 2px 2px var(--color-black-10));
  }

  &__label {
    font-size: 20px;
  }

  &__title {
    font-size: 36px;
    text-align: center;
  }

  &__text {
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
  }

  &__force-continue {
    padding: 0;
    margin: 100px 0 0;
    font-size: 14px;
    color: var(--color-purple);
    border: none;
  }

  &__comment {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--color-gray-20);
    transform: translateY(-20px);
  }
}
