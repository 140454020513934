@keyframes progressbar {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(var(--progressbar-pane-width) - var(--progressbar-width)));
  }

  100% {
    transform: translateX(0);
  }
}

.loader-progress {
  --progressbar-pane-width: 129px;
  --progressbar-width: 38px;

  position: relative;
  width: var(--progressbar-pane-width);
  height: 2px;
  background-color: var(--color-black-40);

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80%;
  }

  &::after {
    position: absolute;
    top: 0;
    width: var(--progressbar-width);
    height: 2px;
    color: var(--color-yellow-10);
    content: '';
    background-color: var(--color-yellow-10);
    animation: progressbar 1.8s cubic-bezier(.5, 0, .5, 1) infinite alternate;
  }

  &_light {
    &::after {
      background-color: var(--color-purple-10);
    }
  }

  &_light &__wrapper {
    background-color: var(--color-red);
  }
}
