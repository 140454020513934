.image-button {
  --loading-display: block;

  position: relative;
  flex-shrink: 0;
  width: 100%;
  aspect-ratio: 1;
  padding: 2px;
  overflow: hidden;
  cursor: pointer;
  outline: solid 2px var(--color-white);
  outline-offset: -2px;
  transition: outline .3s ease-in;

  &_active,
  &_with-actions:hover {
    outline-color: var(--color-purple-10);
  }

  &:hover &__icon-container {
    opacity: 1;
  }

  &__loading-container {
    display: var(--loading-display);
  }

  &__skeleton {
    position: absolute;
    inset: 2px;
    width: auto;
    border-radius: initial;
  }

  &__icon-container {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
    opacity: 0;
    transition: opacity .3s ease-out;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;

    &_yellow {
      background-color: var(--color-yellow-40);
    }

    &_circle {
      padding: 5px;
      border-radius: 50%;
    }

    &_black {
      --bg-colour: var(--color-black-20);
      color: var(--color-white);
      background-color: var(--bg-colour);
      border-color: var(--bg-colour);

      &:hover {
        color: var(--color-white);
        background-color: var(--bg-colour);
        border-color: var(--bg-colour);
      }
    }
  }
}

.canvas {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
