@import '@/styles/dashboard';

.dashboard {
  @include dashboard;
}

.content {
  height: 100%;

  &__loader_small {
    height: 80px;
  }

  &__search {
    min-width: 350px;
  }

  &__button {
    width: 45px;
    height: 45px;
    padding: 0;
  }

  &__select-item {
    line-height: 24px;
  }

  &__switch-item {
    --checkbox-color-slider-unchecked: var(--light-color-gray-5);
    --checkbox-color-switch-unchecked: var(--light-gray)
  }
}

.dashboard-data {
  &__content-loader {
    height: 100%;
  }

  &__error {
    margin: 50px auto;
    font-size: 36px;
    color: var(--color-gray-70);
    word-wrap: break-word;
  }
}

.dashboard-header {
  &__metadata {
    margin-top: 20px;
  }

  &__project-team-data {
    padding-top: 12px;
  }

  &__project-team-members {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &__plus-icon {
    color: var(--color-gray-70);
  }
}

.card-placeholder {
  height: 425px;
}

.panel-placeholder {
  height: 200px;
}
