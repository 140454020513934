.color-scheme {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.color-button {
  width: 55px;
  height: 55px;
  border-radius: initial;
}

.color-circle {
  width: 48px;
  height: 48px;
  border-radius: initial;
  transition: border-radius .2s ease-out;

  &_selected {
    border-radius: 50%;
  }
}
