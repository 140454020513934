.page-header {
  --page-header-gap: 40px;
  --template-columns: 2;
  --page-header-title-font-size: 44px;

  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 2fr) repeat(var(--template-columns), 1fr);
  gap: var(--page-header-gap);
  padding-top: 8px;

  &__section {
    position: relative;

    & + & {
      &::after {
        position: absolute;
        top: 12px;
        bottom: 0;
        left: calc(-1 * var(--page-header-gap) / 2);
        width: 1px;
        content: '';
        background-color: var(--color-black-20);
      }
    }
  }

  &__title {
    font-size: var(--page-header-title-font-size);
    font-weight: normal;
    line-height: 1.15;
    text-align: left;
    letter-spacing: -.75px;
    word-break: break-word;

    &_statistics {
      --page-header-title-font-size: 50px;
    }
  }

  &__subtitle {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &__textarea {
    font-size: var(--page-header-title-font-size);
    font-weight: normal;
    line-height: 1.15;
    text-align: left;
    letter-spacing: -0.75px;
    word-break: break-word;
  }
}
