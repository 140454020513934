.status {
  justify-content: flex-start;
  width: max-content;
}

.panel {
  &__content {
    --max-column-width: 100%;

    min-height: auto;
  }
}

.content-block {
  &::after {
    display: none;
  }

  &__title {
    border-bottom: solid 1px var(--light-color-gray-10);
  }
}
