@import '@/styles/variables';

.footer {
  --trigger-opacity: 1;

  position: fixed;
  right: var(--scrollbar-size);
  bottom: -45px;
  left: var(--page-sidebar-width);
  z-index: var(--z-footer);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  color: var(--light-gray);
  background: var(--color-white-20);
  transition: bottom .3s ease-out, padding-top .3s ease-out;

  &::after {
    position: absolute;
    top: -6px;
    right: 0;
    left: 0;
    height: 10px;
    content: '';
    background: linear-gradient(to top, var(--color-white-20), transparent);
    transition: top .3s ease-out;
  }

  &_visible,
  &:hover {
    --trigger-opacity: 0;
    bottom: 0;
    padding-top: 20px;
  }

  &_visible {
    &::after {
      top: 0;
      right: 20px;
      left: 20px;
      height: 1px;
      background: var(--light-color-gray-10);
    }
  }

  &__link {
    @include default-link;

    color: var(--light-gray);
  }

  &__open-trigger {
    position: absolute;
    bottom: 100%;
    left: 50%;
    z-index: calc(var(--z-footer) + var(--z-above));
    width: 50px;
    height: calc(20px * var(--trigger-opacity));
    background-color: var(--color-white-20);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 0 10px var(--color-white-20);
    transition: height .3s ease-out;
    transform: translateX(-50%);
  }

  &__open-trigger-image {
    position: absolute;
    top: 8px;
    left: 50%;
    color: var(--light-gray);
    opacity: var(--trigger-opacity);
    transition: opacity .3s ease-out;
    transform: translateX(-50%);
  }
}

.footer-info {
  display: flex;
  gap: 7px;
  justify-content: space-between;
}

