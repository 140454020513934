.modal {
  padding-top: 10px;
  background-color: var(--color-white-20);

  &__header {
    align-items: flex-start;
  }

  &__header-block {
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow: clip;
  }

  &__chart-with-gates {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__controls {
    position: relative;
    display: grid;
    grid-template-columns: 2fr 0.5fr 0.5fr;
    gap: 5px;
    align-items: center;
    padding: 10px 20px;

    &::before {
      position: absolute;
      top: 0;
      left: 50%;
      width: 92%;
      height: 1px;
      content: '';
      background-color: var(--light-color-gray-10);
      transform: translateX(-50%);;
    }
  }

  &__button {
    width: 80px;
    height: 30px;
  }

  &__checkbox {
    justify-content: start;
  }

  &__gate-list {
    --controls-height: 55px;
    height: calc(100% - var(--controls-height));
  }
}
