@import '@/styles/variables';

@mixin tabs {
  .tabs {
    display: flex;
    align-items: center;

    &__container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__panel {
      height: 100%;

      &_inactive {
        display: none;
      }
    }

    &__tab-panel {
      height: 100%;
    }

    &__item {
      position: relative;
      height: var(--header-height);
      padding: 20px;
      color: var(--light-gray);
      cursor: pointer;
      background: none;
      border: none;

      &:hover {
        color: var(--color-black);
        transition: color .3s ease-out;
      }

      &::after {
        position: absolute;
        right: 0;
        bottom: -1px;
        left: 0;
        height: 1px;
        content: '';
        background-color: currentcolor;
        transition: transform 0.3s;
        transform: scale3d(0, 1, 1);
      }

      &_selected,
      &:focus-visible,
      &:focus,
      &:active {
        color: var(--color-black);
        outline: none;

        &::after {
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }
}
