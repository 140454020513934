@keyframes splash {
  0% {
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes splash-close {
  0% {
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    opacity: 0;
    transform: translateY(0);
  }
}


@keyframes slide {
  0% {
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    opacity: 0;
    transform: translate(100%, 0);
  }

  100% {
    box-shadow: -5px 8px 14px 0 rgba(34, 60, 80, .26);
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes slide-close {
  0% {
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    opacity: 1;
    transform: translate(0, 0);
  }

  100% {
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    opacity: 0;
    transform: translate(100%, 0);
  }
}

.modal {
  --modal-color: var(--color-white);
  --modal-border-radius: 20px;
  --modal-background-color: var(--color-black-10);

  display: flex;
  flex-direction: column;
  max-width: 95vw;
  height: fit-content;
  min-height: min(390px, 100vh);
  max-height: 100vh;
  padding: 20px;
  overflow: auto;
  color: var(--modal-color);
  background-color: var(--modal-background-color);
  outline: none;

  &_portal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-modal);
    width: 100%;
  }

  &_overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);

    &_slide-animation {
      animation: slide .3s cubic-bezier(.38, .1, .36, .9) forwards;
    }

    &_slide-animation-close {
      animation: slide-close .3s cubic-bezier(.38, .1, .36, .9) forwards;
    }

    &_splash-animation {
      animation: splash .3s cubic-bezier(.38, .1, .36, .9) forwards;
    }

    &_splash-animation-close {
      animation: splash-close .3s cubic-bezier(.38, .1, .36, .9) forwards;
    }
  }

  &_no-overlay {
    background-color: initial;
  }

  &_fullscreen {
    --indent: 40px;

    width: calc(100vw - var(--indent));
    max-width: calc(100vw - var(--indent));
    height: calc(100vh - var(--indent));
    max-height: calc(100vh - var(--indent));
  }

  &__form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.modal_open {
  height: 100%;
}

.modal-body_tag {
  overflow: hidden;
}

.modal_sidebar {
  position: fixed;
  top: 20px;
  bottom: 0;
  height: calc(100vh - 40px);
  overflow: hidden;
  border-radius: 0;

  &_left {
    left: 0;
  }

  &_right {
    right: 20px;
  }
}

.default {
  --modal-background-color: var(--color-white);
  --modal-border-radius: 20px;
  --modal-color: var(--color-black-40);
}

.yellow {
  --modal-background-color: var(--color-yellow-30);
  --modal-border-radius: 20px;
  --modal-color: var(--color-black-40);
}

.dark {
  --modal-background-color: var(--color-black-40);
  --modal-border-radius: 20px;
  --modal-color: var(--color-gray-20);
}

.light {
  --modal-background-color: var(--color-white);
  --modal-border-radius: 20px;
  --modal-color: var(--color-black-40);
}

.blue {
  --modal-background-color: var(--color-blue);
  --modal-border-radius: 20px;
  --modal-color: var(--color-white);
}

.purple {
  --modal-background-color: var(--color-purple-10);
  --modal-border-radius: 20px;
  --modal-color: var(--color-white);
}

.storybook {
  &__modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh;
  }

  &__modal {
    width: 500px;
  }

  &__header {
    width: 100%;
  }
}
