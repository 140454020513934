.preview-image {
  width: min(90vw, 90vh);
  height: min(90vw, 90vh);
  padding: 0;

  &__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: var(--color-black);
  }

  &__close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 45px;
    height: 45px;
    color: var(--color-white);
    background: none;
    border: none;
  }

  &__container {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    gap: 8px 20px;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
  }

  &__time-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
  }

  &__time {
    padding: 0 10px;
    color: var(--color-white);
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 23px;
    transition: border .3s ease-out;

    &_selected, &:hover {
      border-color: var(--color-yellow-40);
    }
  }

  &__download-button {
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    padding: 0;
    color: var(--color-white);
    background: none;
    border: none;
  }
}

.canvas {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
