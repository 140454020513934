/**
 * TODO:
 *
 * - Refactor classes and create modifiers to make Specificity === 0,1,0 instead of 0,2,0
 */

.button {
  --button-color: var(--color-white);
  --button-background-color: var(--color-gray);
  --button-border-color: var(--color-gray);
  --button-active-switch-btn-status-color: var(--color-red);

  --button-hover-color: var(--color-black-10);
  --button-hover-background-color: inherit;
  --button-hover-border-color: inherit;

  --button-font-size: 14px;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  padding: 0 16px;
  font-size: var(--button-font-size);
  font-weight: 500;
  color: var(--button-color);
  letter-spacing: .25px;
  cursor: pointer;
  background-color: var(--button-background-color);
  border: solid 2px;
  border-color: var(--button-border-color);
  border-radius: 10px;
  transition: background-color .3s ease-out, border-color .3s ease-out, color .3s ease-out;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: .6;
  }

  &_active,
  &:hover:enabled {
    color: var(--button-hover-color);
    background-color: var(--button-hover-background-color);
    border-color: var(--button-hover-border-color);
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid var(--focus-color);
  }

  &.fit_content {
    width: fit-content;
  }

  &__loading {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 3px solid var(--color-gray);
    border-right: 3px solid var(--color-yellow-10);
    border-radius: 50%;
    animation: spin 1.2s ease-out infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.button.purple {
  --button-color: var(--color-white);
  --button-background-color: var(--color-purple-20);
  --button-border-color: var(--color-purple-20);

  --button-hover-color: var(--color-white);
  --button-hover-background-color: var(--color-purple);
  --button-hover-border-color: var(--color-purple);

  &.outline {
    --button-color: var(--color-white);
    --button-background-color: inherit;
    --button-border-color: var(--color-purple-20);

    --button-hover-color: var(--color-white);
    --button-hover-background-color: var(--color-purple);
    --button-hover-border-color: var(--color-purple);
  }
}

.button.red {
  --button-color: var(--color-white);
  --button-background-color: var(--color-red-10);
  --button-border-color: var(--color-red-10);
  --button-active-switch-btn-status-color: var(--color-white);

  --button-hover-color: var(--color-white);
  --button-hover-background-color: var(--color-red-20);
  --button-hover-border-color: var(--color-red-20);

  &.outline {
    --button-color: var(--color-white);
    --button-background-color: inherit;
    --button-border-color: var(--color-red-10);

    --button-hover-color: var(--color-white);
    --button-hover-background-color: var(--color-red-20);
    --button-hover-border-color: var(--color-red-20);
  }
}

.button.light-purple {
  --button-color: var(--color-white);
  --button-background-color: var(--color-purple-10);
  --button-border-color: var(--color-purple-10);

  --button-hover-color: var(--color-white);
  --button-hover-background-color: var(--color-purple);
  --button-hover-border-color: var(--color-purple);

  &.outline {
    --button-color: var(--color-white);
    --button-background-color: inherit;
    --button-border-color: var(--color-purple-10);

    --button-hover-color: var(--color-white);
    --button-hover-background-color: var(--color-purple-40);
    --button-hover-border-color: var(--color-purple-40);
  }
}

.button.yellow {
  --button-color: var(--color-black-10);
  --button-background-color: var(--color-yellow-40);
  --button-border-color: var(--color-yellow-40);

  --button-hover-color: var(--color-black-10);
  --button-hover-background-color: var(--color-yellow);
  --button-hover-border-color: var(--color-yellow);

  &.outline {
    --button-color: var(--color-white);
    --button-background-color: inherit;
    --button-border-color: var(--color-yellow-30);

    --button-hover-color: var(--color-black-10);
    --button-hover-background-color: var(--color-yellow-10);
    --button-hover-border-color: var(--color-yellow-10);
  }
}

.button.light {
  --button-color: #b3b3b3;
  --button-border-color: var(--color-white);
  --button-background-color: var(--color-white);
  --button-hover-color: var(--color-black-10);
  --button-hover-background-color: #f2f2f2;
  --button-hover-border-color: #f2f2f2;

  &.outline {
    --button-color: var(--color-white);
    --button-background-color: inherit;
    --button-border-color: var(--color-gray-20);

    --button-hover-color: var(--color-black-10);
    --button-hover-background-color: var(--color-white-10);
    --button-hover-border-color: var(--color-white-10);
  }
}

.button.dark {
  --button-color: var(--color-white);
  --button-background-color: var(--color-black-10);
  --button-border-color: var(--color-black-10);

  --button-hover-color: var(--color-white);
  --button-hover-background-color: var(--color-gray);
  --button-hover-border-color: var(--color-gray);

  &.outline {
    --button-color: var(--color-white);
    --button-background-color: inherit;
    --button-border-color: var(--color-black-10);

    --button-hover-color: var(--color-white);
    --button-hover-background-color: var(--color-gray);
    --button-hover-border-color: var(--color-gray);
  }
}

.button.gray {
  --button-color: var(--color-white);
  --button-background-color: var(--color-gray);
  --button-border-color: var(--color-gray);

  --button-hover-color: var(--color-white);
  --button-hover-background-color: var(--color-gray-10);
  --button-hover-border-color: var(--color-gray-10);

  &.outline {
    --button-color: var(--color-white);
    --button-background-color: inherit;
    --button-border-color: var(--color-gray);

    --button-hover-color: var(--color-white);
    --button-hover-background-color: var(--color-gray-10);
    --button-hover-border-color: var(--color-gray-10);
  }
}

.button.gray.bg-light {
  --button-color: var(--color-white);
  --button-background-color: var(--color-gray-40);
  --button-border-color: var(--color-gray-40);

  --button-hover-color: var(--color-white);
  --button-hover-background-color: var(--color-gray-20);
  --button-hover-border-color: var(--color-gray-20);

  &.outline {
    --button-color: var(--color-white);
    --button-background-color: inherit;
    --button-border-color: var(--color-gray-40);

    --button-hover-color: var(--color-white);
    --button-hover-background-color: var(--color-gray-20);
    --button-hover-border-color: var(--color-gray-20);
  }
}

.button.dark.bg-light {
  --button-color: var(--color-white);
  --button-background-color: var(--color-black-10);
  --button-border-color: var(--color-black-10);

  --button-hover-color: var(--color-white);
  --button-hover-background-color: var(--color-gray-60);
  --button-hover-border-color: var(--color-gray-60);

  &.outline {
    --button-color: var(--color-white);
    --button-background-color: inherit;
    --button-border-color: var(--color-black-10);

    --button-hover-color: var(--color-white);
    --button-hover-background-color: var(--color-gray-60);
    --button-hover-border-color: var(--color-gray-60);
  }
}

.button_empty,
.button_empty:hover:enabled,
.button_empty:focus:focus-visible {
  justify-content: unset;
  padding: inherit;
  color: inherit;
  text-align: unset;
  cursor: pointer;
  background: none;
  border: none;
  outline: inherit;
  // height: unset;
}

.button.bg-light.button_active,
.button.button_active.outline {
  --button-color: var(--button-hover-color);
  --button-background-color: var(--button-hover-border-color);
}

.switch-btn {
  --status-color: var(--color-gray-30);
  position: relative;
  justify-content: flex-start;

  &_active {
    --status-color: var(--button-active-switch-btn-status-color);
  }

  &__circle {
    position: absolute;
    right: 10px;
    width: 10px;
    height: 10px;
    content: '';
    background-color: var(--status-color);
    border-radius: 50%;
    transition: background-color .3s ease-out;
  }
}

.button.white {
  --button-color: var(--color-black-5);
  --button-border-color: var(--color-white-20);
  --button-background-color: var(--color-white-20);
  --button-hover-background-color: var(--color-white);
  --button-hover-border-color: var(--color-white);
}
