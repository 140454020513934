.step-actions {
  display: flex;
  flex-direction: row;
  column-gap: 90px;
  justify-content: flex-end;
  padding-top: 20px;

  &__group {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }

  &__button {
    padding-inline: 30px;

    &_disabled {
      background-color: var(--color-white-10);
      border-color: var(--color-white-10);
    }
  }
}
