@import '@/styles/variables';

.experiment-list-item-card {
  display: block;
  height: 100%;
  border-radius: 20px;
  transition: .3s box-shadow;

  &:hover {
    box-shadow: 0 0 10px 0 var(--shadow-color);
  }

  &:focus-visible {
    outline: 2px solid var(--focus-color);
  }

  &__panel {
    height: 100%;
  }

  &__header {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      content: '';
      background-color: #323232;
    }
  }

  &__title {
    @include title-format;
    @include resizeLinearMd(font-size, 20, 25);
    font-size: 25px;
    font-weight: normal;
  }

  &__group_content {
    gap: 15px;
    justify-content: space-between;
  }

  &__total-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--light-color-gray-10);;
  }

  &__number {
    font-size: 35px;
    line-height: 1.15;
    letter-spacing: -.25px;
  }

  &__icons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
  }
}
