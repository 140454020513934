.gates-container {
  position: absolute;
  overflow-x: clip;
  overflow-y: visible;
  pointer-events: none;

  &_disabled .gate-element,
  &_disabled .gate-element__control {
    pointer-events: none;
  }

  &_active {
    z-index: 0;
    pointer-events: all;
  }

  &_gates-disabled {
    .gate-element {
      pointer-events: none;
    }
  }

  &_gates-static {
    .gate-element__control {
      cursor: inherit;
      visibility: hidden;

      &:hover {
        cursor: inherit;
      }
    }
  }

  &_full-actions .gate-element,
  &_full-actions .gate-element__control {
    pointer-events: all;
  }
}

.gate-group {
  &_active {
    .gate-element {
      pointer-events: all;
      stroke: var(--color-red) !important;
    }

    .gate-element__control {
      opacity: 1;
      fill: var(--color-red) !important;
    }

    .gate-menu {
      visibility: visible;
    }
  }

  &:hover {
    .gate-element__control {
      opacity: 1;
    }

   .gate-menu {
      pointer-events: all;
      visibility: visible;
    }
  }
}

.gate-element {
  pointer-events: none;
  fill: rgba(0, 0, 0, 0);
  stroke: var(--color-black);
  stroke-dasharray: 2, 2;
  stroke-width: 2px;

  &_no-events {
    pointer-events: none !important;
  }

  &__control {
    pointer-events: all;
    cursor: move;
    opacity: 0;
  }

  &:hover {
    cursor: move;
  }
}

.gate-label {
  pointer-events: all;

  &__label-bg {
    fill: var(--color-white-20);
  }

  &__text {
    font-weight: 600;
    color: var(--color-black);
    user-select: none;
  }

  &_hidden {
    visibility: hidden;
  }

  &:hover .gate-menu {
      visibility: visible;
  }
}

.gate-menu {
  pointer-events: all;
  cursor: pointer;
  visibility: hidden;
  fill: var(--color-white-20);
  
  &__marker {
    fill: var(--color-black);
  }
}

.overflow-container {
  --select-height: 30px;
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100% - var(--select-height));
  overflow: hidden;
  pointer-events: none;
}
