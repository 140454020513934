:global(.rc-slider) {
  --track-color: var(--color-black-20);
  --handle-border-color: var(--color-black-20);
  background: none;
}

:global(.rc-slider-disabled) {
  --track-color: var(--icons-border-color);
  --handle-border-color: var(--color-gray-80);
}

.slider {
  --track-color: var(--theme-slider-track-color, var(--color-white));
  --color: var(--theme-slider-color, var(--color-gray-20));
  --handle-bg-color: var(--color-white);
  --rail-color: var(--icons-border-color, var(--color-gray));
  --handle-outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: var(--color);

  &_disabled {
    --track-color: var(--color-gray-40);
    --handle-border-color: var(--color-gray-80);
    --color: var(--theme-slider-color-disabled, var(--color-gray-20));

    cursor: not-allowed;
  }

  &__label {
    display: flex;
    gap: var(--range-gap);
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    font-size: 17px;
    color: var(--light-gray);
  }

  :global(.rc-slider) {
    --half-handle-width: 9px;
    width: calc(100% - var(--half-handle-width));
    padding-left: var(--half-handle-width);
    background: none;
  }

  :global(.rc-slider-handle) {
    --half-handle-width: 9px;
    width: calc(100% - var(--half-handle-width));
    padding-left: var(--half-handle-width);
    background-color: var(--handle-bg-color) !important;
    border-color: var(--handle-border-color) !important;
    outline: none !important;
  }

  :global(.rc-slider-handle-dragging) {
    --handle-bg-color: var(--color-black);
  }

  &__range {
    :global(.rc-slider) {
      padding-left: 0;
    }
  }

  &__range-values {
    display: flex;
    gap: var(--range-gap);
    justify-content: space-between;
    width: 100%;
  }

  &__range-value {
    width: min-content;
  }

  &__range-zero-value {
    input {
      color: var(--light-gray);
    }
  }

  &__label-range {
    flex-direction: column;
    font-size: 16px;
  }
}
