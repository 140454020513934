@import '@/styles/variables';

.chart {
  --modebar-height: 45px;
  --padding-gap: 20px;
  --header-height: 50px;

  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: calc(100% - var(--header-height));
  padding-top: var(--padding-gap);
  container-type: size;

  &__no-data {
    --card-header-height: 45px;
    --left-axis-width: 25px;

    position: absolute;
    top: calc(50% - var(--card-header-height));
    left: calc(50% + var(--left-axis-width));
    width: 80%;
    user-select: none;
    transform: translateX(-50%);
  }

  &__controls {
    z-index: -10;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .axis-switch {
    --axis-switch-cursor: pointer;

    @include swap-chart-grid-position;
    align-self: end;
    padding: 0;
    cursor: var(--axis-switch-cursor);

    &_disabled {
      --axis-switch-cursor: not-allowed;
    }

    &__icon {
      border: solid 1px var(--icons-border-color);
      border-radius: 10px;
    }
  }
}

.skeleton {
  position: absolute;
  left: 0;
  z-index: 2;
  width: 100%;
  height: calc(100% - var(--padding-gap));
}

.modebar {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  height: 49px;

  &__control-item {
    --control-item-color: var(--light-color-gray-15);
    --control-item-bg-color: unset;

    display: grid;
    place-content: center;
    width: 40px;
    height: 45px;
    padding: 0;
    color: var(--control-item-color);
    background-color: var(--control-item-bg-color);
    border-radius: 0;
    transition: color .3s ease-out;

    &:hover {
      --control-item-color: var(--color-black);
      --control-item-bg-color: var(--color-yellow-10);
    }

    &:disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: .6;
    }

    &_selected {
      --control-item-color: var(--color-black);
      --control-item-bg-color: var(--color-yellow-10);
    }
  }

  &__select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px;
    height: 45px;
    text-align: left;
    background-color: var(--color-white-20);
    border-radius: 10px;

    & > div {
      width: 100%;
    }
  }

  &__entities-search {
    margin-left: auto;
  }

  &__entities-popover {
    margin-bottom: 25px;
  }
}

.popover {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
}

.plot {
  --aside-control-bar-width: 45px;
  --plotly-right-gap: 20px;
  --container-width-limitation: calc(var(--aside-control-bar-width) + var(--plotly-right-gap));

  @include chart-container-grid;

  position: relative;
  z-index: -3;
  width: calc(100% - var(--container-width-limitation));
  height: calc(100% - var(--modebar-height));

  &__container {
    @include plotly-chart-grid-position;
    z-index: -2;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__x-axis {
    @include x-axis-grid-position;
  }

  &__y-axis {
    @include y-axis-grid-position;
  }
}

.plot-chart {
  position: relative;
  width: 100%;
  height: 100%;
}
