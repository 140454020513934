.member {
  --member-border-color: var(--color-gray-80);
  --member-color: var(--light-gray);
  --member-bg-color: var(--color-white);

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--member-size);
  height: var(--member-size);
  font-size: 12px;
  font-weight: bold;
  color: var(--member-color);
  background-color: var(--member-bg-color);
  border: 2px solid var(--member-border-color);
  border-radius: 50%;

  &::before {
    position: absolute;
    inset: -2px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    content: attr(data-initials);
    background-color: inherit;
    border: inherit;
    border-radius: 50%;
    opacity: 0;
    transition: inset .3s ease-out, opacity 0s .3s ease-out;
    will-change: inset;
  }

  &:hover::before {
    inset: -5px;
    opacity: 1;
    transition: inset .3s ease-out;
  }
}

.member-list {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: var(--member-list-justify, flex-start);

  &_reverse {
    flex-direction: row-reverse;
  }

  &_single {
    width: var(--member-size);
    max-width: var(--member-size);
  }

  &__member-wrap {
    position: relative;
    flex-grow: 1;
    min-width: 10px;
    max-width: 25px;
    height: var(--member-size);

    &_single {
      width: var(--member-size);
    }
  }
}
