.dataset-chart {
  --theme-select-bg-color: var(--empty);
  border-bottom: 1px solid var(--light-color-gray-10);

  &__chart-container {
    padding-left: 0;
  }

  &_disabled {
    cursor: not-allowed;
    opacity: .5;

    & * {
      pointer-events: none !important; // important used to override plotly styles
    }
  }
}
