/** TODO: Refactor - use one component for the inputs. See: TextInput */

.input {
  display: inline-block;
  width: 100%;
  min-height: 40px;
  padding: 8px 10px;
  line-height: 1.25;
  text-overflow: ellipsis;
  background-color: var(--color-white);
  border: 1px solid var(--light-color-gray-10);
  border-radius: 10px;

  &:focus-visible {
    border-color: transparent;
    outline: 2px solid var(--focus-color);
  }
}
