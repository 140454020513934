.step-cytokine-summary {
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: 100%;
  margin: 25px 15px;
  background-color: var(--color-white-15);
  border-radius: 15px;
}

.summary {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
  padding: 25px;

  &__title {
    padding-bottom: 25px;
    font-size: 20px;
    font-weight: bold;
  }

  &__text {
    padding-bottom: 20px;
  }

  &__beads {
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
  }

  &__bead {
    position: relative;
    margin-left: 40px;
    font-weight: bold;

    &::before {
      position: absolute;
      top: 3px;
      left: -15px;
      font-size: 12px;
      content: '•';
    }
  }
}

.illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-left: 1px solid var(--light-color-gray-10);
  margin-block: 30px;
}

.circles {
  position: relative;
  z-index: 1;
  font-size: 20px;
  font-weight: bold;
  color: var(--color-gray-50);
}

.circle {
  position: absolute;
  top: -65px;
  z-index: -1;
  width: 160px;
  height: 160px;
  background-color: var(--light-color-gray-101);
  border-radius: 50%;

  &_left {
    margin-left: -45px;
  }

  &_right {
    margin-left: 45px;
  }
}

.actions {
  border-top: 1px solid var(--light-color-gray-10);
}
