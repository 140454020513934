@import '@/styles/variables';

.assays {
  &__cards-container {
    --min-column-width: 420px;
    --column-count: auto-fill;

    display: grid;
    grid-template-columns: repeat(var(--column-count), minmax(var(--min-column-width), 1fr));
    gap: 20px;
    margin-bottom: 20px;

    @include xl {
      --min-column-width: 500px;
    }

    @include lg {
      --column-count: 3;
    }
  }
}
