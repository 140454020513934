.chart-heading {
  display: flex;
  gap: 20px;
  align-items: center;

  &__name {
    font-size: 17px;
  }

  &__select {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    text-align: center;
    background-color: var(--color-white-20);
    border-radius: 20px;
  }
}

.select {
  &__object-type {
    width: 95px;
  }
}
