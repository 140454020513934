.scatterplot {
  --margin-right: 20px;

  width: 100%;
  height: 100%;
  min-height: 400px;

  &_hidden {
    visibility: hidden;
  }

  &__title {
    font-size: 50px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.2;
    color: var(--color-black);
    letter-spacing: -.75px;
  }

  &__charts {
    position: relative;
    display: grid;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    height: max-content;
  }

  &__gates {
    --bottom-gap: 0;

    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 20px;
  }
}

.card__chart {
  width: max-content;
  height: max-content;
  max-height: 100px;
}
