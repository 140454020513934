
.warning_block {
  display: flex;
  gap: 3px;
  max-width: 270px;
  padding-left: 5px;
  font-size: 14px;
  letter-spacing: .1px;

  &__button {
    width: max-content;
    height: max-content;
    padding: 0;
    margin-left: 2px;
    font-size: 14px;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
    text-underline-offset: 4px;

    &:hover:enabled {
      color: var(--color-purple);
    }
  }
}
