.preprocessing-step {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 50%;
  }

  &__charts-wrapper {
    --margin-right: 20px;
    display: flex;
    flex-grow: 1;
    gap: 20px;
    width: 100%;
    height: 100%;
    min-height: 400px;
    border-bottom: 1px solid var(--light-color-gray-10);
  }

  &_hidden {
    display: none !important;
  }

  &_matrix {
    --action-button-container-height: 65px;
    height: calc(100% - var(--action-button-container-height));
  }
}

.charts-block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  &__header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding-block: 13px;
    font-weight: bold;
    border-bottom: 1px solid var(--light-color-gray-10);
  }

  &__charts {
    position: relative;
    display: grid;
    gap: 5px;
    justify-content: space-between;
    width: 100%;
    padding-right: 5px;
    overflow: auto;
    padding-block: 15px;
  }
}

.general-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  &__gates {
    padding-top: 12px;
    border-left: 1px solid var(--light-color-gray-10);
  }

  &__gates-header {
    padding-bottom: 12px;
  }
}

.select {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  overflow: hidden;
  font-weight: initial;
  text-align: center;
  background-color: var(--color-white-20);
  border-radius: 20px;
}

.custom-instruction {
  display: flex;
  gap: 10px;
  align-items: center;

  &_disabled {
    opacity: .5;
  }
}
