.grid-minimap {
  --theme-panel-background-color: var(--color-black-10);
  --theme-panel-header-font-size: 14px;
  --theme-panel-border-radius: 10px;

  --close-color: var(--color-gray-10);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 600px;

  &__panel {
    z-index: 100;
    width: fit-content;
    max-width: 100%;
    padding-inline: 20px;
  }

  &__header-wrapper {
    padding-bottom: 10px;
    cursor: pointer;
    padding-block: 15px;
    padding-inline: 0;
  }

  &__header-wrapper:hover {
    --close-color: var(--color-white);
  }

  &__header {
    justify-content: space-between;
    color: inherit;
  }

  &__mini-header {
    padding-bottom: 0;
  }

  &__rows-wrapper {
    --grid-circles-wrapper-radius: var(--template-grid-circles-wrapper-radius, 0);
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    border-radius: var(--grid-circles-wrapper-radius);
  }

  &__grid-row-wrapper {
    --grid-row-height: var(--template-grid-row-height, 16px);

    display: flex;
    column-gap: 10px;
    align-items: center;
    height: var(--grid-row-height);
    font-size: 10px;
  }

  &__columns-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__grid-content {
    flex-direction: row;
    column-gap: 16px;
    padding: 15px 0 20px 15px;
    touch-action: none;
  }

  &__grid-numbers-wrapper {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
  }

  &__grid-row-number {
    position: absolute;
    left: 20px;
    z-index: 10;
    background-color: var(--color-black-10);
  }

  &__close {
    height: fit-content;
    color: var(--close-color);
    pointer-events: none;
  }

  &__lane-info {
    display: flex;
    column-gap: 90px;
    align-items: center;
    justify-content: space-between;
  }

  &__mini-lane-info {
    column-gap: 12px;
    width: 100%;
  }

  &__from-to-block {
    position: absolute;
    right: -72px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    transition: right .2s ease-out;
  }

  &__from-to-block-show {
    right: 0;
  }

  &__from-to-wrapper {
    position: relative;
    width: 115px;
    height: 27px;
    overflow: hidden;
  }

  &__from {
    display: flex;
    justify-content: center;
    width: 40px;
    padding: 3px;
    color: var(--color-gray-10);
  }

  &__to {
    display: flex;
    justify-content: center;
    width: 40px;
  }

  &__to-block {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    padding: 3px 7px;
    background-color: var(--color-black-40);
    border-radius: 13px;
  }

  &__to-block-transparent {
    opacity: 0;
  }

  &__disabled {
    opacity: .6;
  }
}

.grid-minimap__circle-wrapper {
  padding-inline: 2px;
  padding-block: 1px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.grid-minimap__circle {
  --circle-background-color: var(--color-gray-40);
  --grid-circle-size: var(--template-grid-circle-size, 9px);
  display: block;
  width: var(--grid-circle-size);
  height: var(--grid-circle-size);
  background-color: var(--circle-background-color);
  border-radius: 50%;
  transition: background-color .1s ease-out;

  &_10 {
    --circle-background-color: var(--color-gray-20);
  }

  &_hover {
    --circle-background-color: var(--color-white);
  }

  &_active {
    --circle-background-color: var(--color-yellow-30);

    &:hover {
      --circle-background-color: var(--color-yellow-10);
    }
  }

  &_hidden {
    opacity: 0;
  }

  &_disabled {
    cursor: not-allowed;
  }
}

.grid-minimap__magnifier {
  --left: 0;
  --top: 0;
  --template-grid-row-height: 13px;
  --template-grid-circles-wrapper-radius: 50%;
  position: absolute;
  top: var(--top);
  left: var(--left);
  z-index: 1001;
  background-color: var(--color-black-30);
  scale: 2;
}

.grid-minimap__transparent {
  pointer-events: none;
  touch-action: none;
  opacity: 0;
}
