.viewer {
  --cage-location-color: transparent;
  --segmented-object-color: transparent;

  position: relative;
  left: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #3f3f3f;
  border-radius: 15px;
  transform: translateX(-50%);

  &_square {
    --full-size: calc(var(--viewer-bonus-height, 0) + var(--viewer-height, 700));

    width: calc(var(--full-size) * 1px);
    height: calc(var(--full-size) * 1px);
  }

  &__osd-wrap {
    position: relative;
    height: 100%;
  }

  &__with-cage-locations {
    --cage-location-color: #6750a4;
  }

  &__with-segmented-objects {
    --segmented-object-color: #e1d274;
  }

  .cage-location {
    color: var(--cage-location-color);
  }

  .segmented-object {
    color: var(--segmented-object-color);
  }

  &__loader {
    position: absolute;
    inset: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-black-10);
    border-radius: 20px;
    opacity: 1;
    transition: opacity 0s ease-out;
  }

  &__loaded &__loader {
    pointer-events: none;
    opacity: 0;
    transition: opacity .5s ease-out;
  }

  :global(.openseadragon-container) {
    opacity: 0;
  }

  :global(.openseadragon-container:first-child) {
    z-index: 1;
    opacity: 1;

    canvas {
      opacity: 0;
    }
  }

  &__addon {
    --addon-offset: 20px;

    position: absolute;
    z-index: 1;

    &_left {
      left: var(--addon-offset);
    }

    &_right {
      right: var(--addon-offset);
    }

    &_top {
      top: var(--addon-offset);
    }

    &_bottom {
      bottom: var(--addon-offset);
    }
  }
}
