@import '@/styles/tabs';

@include tabs;

.experiment {
  display: flex;
  height: 100%;

  &__container {
    flex-grow: 1;
    width: 100%;
  }

  :global(#images-container) {
    --min-gap: 1px;

    height: calc(100% - var(--min-gap));
  }

  &__search {
    min-width: 350px;
  }

  &__error {
    width: fit-content;
    margin: 50px auto;
    font-size: 36px;
    color: var(--color-gray-70);
  }
}

.controls {
  &__button {
    display: flex;
    justify-content: center !important;
    width: 45px;
    height: 45px;
    padding: 0;
    color: var(--color-black);
    cursor: pointer;
    background-color: var(--color-white);

    &_with-text {
      gap: 10px;
      width: max-content;
      padding: 10px 15px;
      background-color: var(--color-yellow-40);
      border-color: var(--color-white-20);
    }
  }
}

.preprocessing-button {
  display: flex;
  column-gap: 12px;
  justify-content: flex-start;
  padding-right: 18px;
  padding-left: 12px;
  line-height: 1.2;
  background-color: var(--color-black-5);
  border: unset;

  &__title {
    display: flex;
    flex-direction: column;
    text-align: start;
  }

  &__assay-count {
    font-weight: bold;
  }

  &__icon {
    width: 30px;
    height: auto;
    color: var(--color-black-5);
    background-color: var(--color-blue-50);
    border-radius: 50%;
  }
}
