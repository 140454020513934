@import '@/styles/variables';

.description-modal {
  overflow: auto;
}

.header__title {
  font-size: 17px;
  font-weight: 600;
}

.description-modal__body {
  width: 735px;
  padding: 5px 0;
}

.description-modal__header {
  padding-bottom: 20px;
}

.body__summary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.summary__details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:last-child {
    grid-column: 2 / 4;
  }
}

.details__title {
  font-size: 50px;
  font-weight: normal;
  line-height: 1.14;
  letter-spacing: -.75px;

  &.title_resized {
    font-size: 17px;
    font-weight: 600;
    line-height: 1.41;
    letter-spacing: -.26px;
  }

  &.title_extra-top-space {
    margin-top: 8px;
  }
}

.details__rows {
  padding: 15px 0 50px;
  margin-top: 50px;
  border-top: solid 1px var(--light-color-gray-10);
}

.body__description {
  padding: 15px 0;
  border-top: solid 1px var(--light-color-gray-10);
}

.body__content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.description__title {
  padding-bottom: 20px;
  font-weight: 600;
}

.link {
  @include default-link;
}
