.panel {
  --panel-line-offset: 20px;
  --line-color: var(--color-gray-40);
  --border-radius: var(--theme-panel-border-radius, 20px);

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  background-color: var(--color-black-10);
  border-radius: var(--border-radius);

  &_default {
    color: var(--color-white);
    background-color: var(--theme-panel-background-color, var(--color-black-10));
  }

  &_yellow {
    color: var(--color-black-40);
    background-color: var(--color-yellow-30);
  }

  &_dark {
    color: var(--color-gray-20);
    background-color: var(--color-black-30);
  }

  &_light {
    --line-color: var(--color-white);
    color: var(--color-black-40);
    background-color: var(--color-white);
  }

  &_blue {
    color: var(--color-white);
    background-color: var(--color-blue);
  }

  &_purple {
    color: var(--color-white);
    background-color: var(--color-purple-10);
  }
}

.panel-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  padding: 20px;
  overflow: auto;
  text-align: left;
}

.panel-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin-bottom: 20px;
  padding: 20px;

  &_divide {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
  }

  &_divide-dark {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: var(--theme-panel-header-font-size, 24px);
    font-weight: normal;
  }
}

.panel-description {
  padding-top: 20px;
  margin-top: 10px;
  padding-inline: 20px;

  &_divide {
    padding-top: 17px;
    border-top: 1px solid rgba(255, 255, 255, .1);
  }

  &_divide-dark {
    padding-top: 17px;
    border-top: 1px solid rgba(0, 0, 0, .1);
  }
}
