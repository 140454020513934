@import '@/styles/variables';

@mixin dashboard {
  display: flex;
  height: 100%;

  &__content {
    flex: 1;
  }

  &__items {
    &_list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &_cards {
      --min-column-width: 420px;
      --column-count: auto-fill;

      display: grid;
      grid-template-columns: repeat(var(--column-count), minmax(var(--min-column-width), 1fr));
      gap: 20px;

      @include xl {
        --min-column-width: 500px;
      }

      @include lg {
        --column-count: 3;
      }
    }
  }
}
