.header {
  position: relative;
  display: grid;
  flex-shrink: 0;
  grid-template-areas: '. actions';
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  background-color: var(--color-white-20);
  border-bottom: 1px solid var(--color-black);
}
