@import '@/styles/variables';

.assay-card {
  &__panel {
    cursor: pointer;

    &_content {
      row-gap: 15px;
      padding-top: 15px;
    }
  }

  &__text {
    flex: 1;
    text-decoration: none;

    &_capital {
      &::first-letter {
        text-transform: capitalize;
      }
    }

    &_title {
      @include resizeLinearMd(font-size, 20, 25);
      display: flex;
      gap: 15px;
      align-items: center;
      padding-bottom: 20px;
      font-size: 25px;
      border-bottom: 1px solid var(--icons-border-color);
    }
  }

  &__total-samples {
    display: flex;
    flex-direction: column;
    gap: 5px;
    line-height: 1;

    &_title {
      font-size: 18px;
    }

    &_count {
      font-size: 28px;
    }
  }

  &__time-points {
    border-top: 1px solid var(--icons-border-color);
    border-bottom: 1px solid var(--icons-border-color);
    padding-block: 15px;

    &_title {
      color: var(--light-gray);
    }
  }

  &__time-points_container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 15px;
  }

  &__time-point {
    display: flex;
    column-gap: 5px;
    align-items: center;
    min-width: 80px;

    &_time {
      font-weight: bold;
    }
  }

  &__additional-info {
    display: flex;
    flex-direction: row;

    &_titles,
    &_values {
      display: flex;
      flex-direction: column;
    }

    &_titles {
      width: 50%;
      color: var(--light-gray);
    }

    &_values {
      white-space: pre-line;
    }
  }
}
