@import '../../variables';

.table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  &__header-item {
    @include header-bottom-border;

    padding-block: 10px;
    font-weight: normal;
    color: var(--light-gray);
  }

  &__column {
    @include item-bottom-border;

    padding-block: 10px;
    padding-right: 10px;

    &:last-child {
      padding-right: 0;
    }

    &_minimized {
      width: 75px;
    }
  }
}

.consumable-name {
  @include list-item-name;

  text-decoration: none;
}

.status {
  justify-content: flex-start;
  width: max-content;
}
