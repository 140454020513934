@import '@/styles/tabs';

@include tabs;

.modal {
  width: min(610px, 95vw);
  height: max-content;
  max-height: 95vh;

  &__header {
    &_no-border {
      border: none;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
  }

  &__input {
    --input-bg-color: var(--color-white);
    --input-border-color: transparent;
    --input-color: var(--color-black);
    --input-border-width: 0;
    --input-padding: 0;
    --input-font-size: 17px;

    width: 100%;
    border-bottom: solid 1px var(--color-gray-70);
  }

  &__input-label {
    color: var(--light-gray);

    &_error {
      color: var(--color-red-error);
    }
  }

  &__collaborator-row {
    display: grid;
    grid-template-columns: 1fr 30px 30px;
    gap: 10px;
    align-items: end;
  }

  &__collaborator-button {
    width: 30px;
    height: 30px;
    padding: 0;
    color: var(--color-white);
    border-radius: 20px;
  }

  &__footer {
    column-gap: 5px;
    justify-content: space-between;
  }

  &__button {
    --button-font-size: 17px;

    height: 50px;
  }

  &__text-area {
    min-height: 50px;
    max-height: 400px;
    resize: block;
  }

  &__content {
    &_no-gap {
      padding: 0;
    }
  }
}

.required {
  color: var(--color-red-required);
}

.select {
  &__label {
    margin-bottom: 15px;
  }

  &__control-container {
    flex-grow: 1;
    border: solid 1px var(--icons-border-color);
    border-radius: 10px;

    &_error {
      border: solid 1px var(--color-red-error);
    }
  }
}

.list-modal {
  background-color: var(--color-white-20);

  &__content {
    flex-grow: unset;
    padding-top: unset;
    overflow-y: unset;
  }
}

.header-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
}

.table-container {
  height: 100%;
  overflow-y: auto;
}

.table {
  width: 100%;
  height: 100%;
  border-spacing: 50px;
  border-collapse: collapse;
  background-color: var(--color-white);

  &__head {
    position: sticky;
    top: 0;
    background-color: var(--color-white);
  }

  &__head-row {
    box-shadow: 0 0 0 1px var(--icons-border-color);
  }

  &__head-item {
    padding: 20px 0;

    &:first-child {
      text-align: left;
    }
  }

  &__row {
    text-align: center;
    border-bottom: solid 1px var(--icons-border-color);

  }

  &__column {
    max-width: 250px;
    padding: 15px 0;
    word-break: break-word;

    &:first-child {
      text-align: left;
    }
  }

}
