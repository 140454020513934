.experiment-data {
  &__content-loader {
    height: 100%;
  }

  &__error {
    width: fit-content;
    margin: 50px auto;
    font-size: 36px;
    color: var(--color-gray-70);
  }
}
