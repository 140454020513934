.resize-control {
  &__controls-wrap {
    display: flex;
    flex-direction: column;
    gap: 1px;
    padding: 0;
    margin: 0;
  }

  &__controls-elem {
    overflow: hidden;

    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  &__square-btn {
    --button-hover-color: var(--color-black-10) !important;
    --button-background-color: var(--color-black-10) !important;
    --button-hover-background-color: var(--color-yellow-30) !important;
    --button-border-color: var(--color-black-10) !important;
    --button-hover-border-color: var(--color-yellow-30) !important;

    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 0;
  }
}
