.update-gate-change-color-modal {
  width: min(610px, 95vw);

  &__color-picker {
    grid-template-columns: repeat(11, 1fr);
    width: min(550px, 95vw);
    margin-top: 10px;
  }

  &__color-block {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 10px;
  }

  &__gate-name {
    position: relative;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__color-input {
    &_hide {
      display: none;
    }
  }

  &__footer {
    column-gap: 5px;
    justify-content: space-between;
  }

  &__button {
    --button-font-size: 17px;

    & button {
      height: 50px;
    }

    &_pencil {
      width: 45px;
      padding: 5px;
    }
  }
}
