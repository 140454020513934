.preprocessing {
  --theme-select-bg-color: var(--color-white-20);
  --theme-datasets-filters-color: var(--color-black-5);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 500;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: var(--color-white-20);
  border-top: 5px solid var(--color-blue-30);

  &__block {
    display: flex;
    flex-direction: column;
    padding: 0 60px 20px calc(var(--page-sidebar-width) + 10px);
    background: var(--color-white);

    &_all-height {
      flex-grow: 1;
      height: 100%;
      overflow: auto;
    }
  }

  &__logo {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 60px;
    height: 60px;
    padding: 10px;
    color: var(--color-black-10);
    background-color: var(--color-white-15);
    border-radius: 10px;
  }
}

.question-block {
  padding-bottom: 38px;
  padding-left: 20px;
  border-bottom: 1px solid var(--light-color-gray-10);

  &__title {
    margin-top: 35px;
    margin-bottom: 38px;
    font-size: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding-left: 54px;
  }

  &__datasets-table {
    padding-left: 0;
  }
}

.close-button,
.close-button:hover:enabled {
  position: absolute;
  top: 5px;
  right: 5px;
  color: var(--color-black);
  border: unset;
}

.datasets-table {
  padding-bottom: 0;
  border-bottom: unset;

  &__table {
    padding-right: 5px;
    padding-left: 0;
  }
}

.radio-button {
  display: flex;
  column-gap: 20px;
  width: fit-content;
  cursor: pointer;

  input[type='radio'] {
    --color: var(--light-color-gray-15);
    position: relative;
    width: 18px;
    height: 18px;
    margin: 0;
    font: inherit;
    color: var(--color);
    cursor: pointer;
    background-color: #fff;
    border: 1px solid var(--color);
    border-radius: 50%;
    transform: translateY(.2em);
    appearance: none;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      margin: -5px 0 0 -5px;
      content: '';
      border-radius: 50%;
      box-shadow: inset 1em 1em var(--color-black);
      transition: 120ms transform ease-out;
      transform: scale(0);
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      margin: -5px 0 0 -5px;
      content: '';
      border: 1px solid var(--color);
      border-radius: 50%;
    }

    &:checked {
      --color: var(--color-black);
    }

    &:checked::before {
      transform: scale(1);
    }
  }
}
