.modal-footer {
  display: flex;
  padding-top: 20px;

  &__divide {
    border-top: 1px solid rgba(136, 136, 136, .3);
  }

  &__sidebar {
    padding: 20px;
  }
}
