.modal {
  width: min(610px, 95vw);
  height: min(335px, 95vh);

  &__row_name {
    margin: 30px 0;
  }

  &__gate-name {
    position: relative;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__footer {
    column-gap: 5px;
    justify-content: space-between;
  }

  &__button {
    --button-font-size: 17px;

    & button {
      height: 50px;
    }
  }
}
