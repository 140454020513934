.plot__container {
  flex-grow: 1;
  overflow: hidden;
}

.chart {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__controls {
    margin-top: 10px;
  }

  &__no-data {
    position: absolute;
    top: 50%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
    transform: translateY(-50%);
  }

}

.modebar {
  display: flex;
  gap: 5px;
  align-items: center;
  width: max-content;
  height: 45px;
}

.plot-chart {
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.skeleton {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}