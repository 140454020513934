.single-chart {
  --chart-height: var(--theme-single-chart-height, 100%);

  &__wrapper-content {
    position: relative;
    offset: initial;
    height: var(--chart-height);
    min-height: 470px;
  }

  &__content {
    --container-top-offset: 360px;
    --bottom-gap: 90px;

    position: relative;
    display: grid;
    flex: 1 0 auto;
    grid-template-columns: calc(100% - var(--sidebar-width)) var(--sidebar-width);
    height: 100%;
    margin: 0 0 20px;
    background-color: var(--color-white);
    border-radius: 20px;
    offset: initial;

    &_full-width {
      grid-template-columns: 1fr 0;

      .single-chart__gates {
        width: 0;
        overflow: hidden;
      }

      .single-chart__container {
        display: flex;
        flex-direction: column;
        padding-right: 20px;
      }

      .single-chart__drawer-button {
        width: 65px;

        &::after {
          left: -1px;
          transition: left .3s ease-out, top .3s ease-out;
        }

        .single-chart__drawer-arrow {
          left: 50%;
          transform: translateX(-50%) rotate(180deg);
        }
      }

      .single-chart__left-block {
        width: 100%;
      }
    }

    &_full-screen {
      position: fixed;
      height: initial;
      margin: 0;
      border-radius: initial;

      .single-chart__drawer-button {
        &::after {
          top: 74px;
          bottom: 20px;
          transition: top .3s ease-out, left .3s ease-out, bottom .3s ease-out;
        }
      }

      .single-chart__gates {
        --gates-full-screen-offset: 60px;

        position: relative;
        margin-top: var(--gates-full-screen-offset);
        transition: margin-top .3s ease-out;

        &::after {
          --line-offset-top: -5px;
          --line-offset-left: -26px;

          position: absolute;
          top: var(--line-offset-top);
          left: var(--line-offset-left);
          width: 100%;
          height: 1px;
          content: '';
          background-color: var(--light-color-gray-10);
        }
      }
    }

    :global(#images-scroll-area),
    :global(#datasets-list) {
      top: 0 !important;
      max-height: 100vh;
    }

    :global(#images-wrapper) {
      height: 100% !important;
    }

    :global(#images-container) {
      --top-inset: 60px;

      height: calc(100% - var(--top-inset)) !important;
    }
  }

  &__gates {
    padding-top: 12px;
    overflow: auto;
    transition: margin-top .3s ease-out;
  }

  &__close-modal-button {
    --icon-size: 15px;

    position: absolute;
    top: 10px;
    right: 20px;
    width: 30px;
    height: 30px;
    padding: 0;
    color: var(--color-black);
    border-radius: 6px;
    opacity: 0 !important;
    transition: opacity .3s ease-out;

    & > svg {
      width: var(--icon-size);
      height: var(--icon-size);
    }

    &_visible {
      opacity: 1 !important;
    }
  }

  &__block {
    display: flex;
    height: var(--chart-height);
    min-height: 470px;
  }

  &__container {
    display: flex;
        flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 12px 0 20px 20px;
  }

  &__header {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--light-color-gray-10);
  }

  &__drawer-button {
    position: relative;
    width: 26px;
    cursor: pointer;

    &::after {
      position: absolute;
      inset-block: 0;
      left: 25px;
      width: 1px;
      content: '';
      background-color: var(--light-color-gray-10);
      transition: left .3s ease-out, inset-block .3s ease-out;
    }
  }

  &__full-screen-button {
    width: 30px;
    height: 30px;
    padding: 0;
    color: var(--color-black);
    border-radius: 6px;
    opacity: 1;
    transition: opacity .3s ease-out;

    & > svg {
      width: 15px;
      height: 15px;
    }

    &_hide {
      opacity: 0 !important;
    }
  }

  &__drawer-arrow {
    position: absolute;
    left: 50%;
    color: var(--color-gray-70);
    transform: translateX(-50%);
  }
}
