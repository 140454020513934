.list-block {
  height: 100%;
  padding-bottom: 5px;
  overflow-y: scroll;

  &__list {
    columns: 2 auto;
    column-gap: 30px;
    max-height: 100%;
  }

  &__item {
    display: grid;
    align-items: center;
  }
}
