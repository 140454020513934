.bead-gate-popover {
  width: 520px;
  padding: 20px;
  padding-top: 15px;
  background-color: var(--color-white);
  box-shadow: 0 0 6px 2px var(--light-color-gray-10);

  &__header {
    position: relative;
    padding-bottom: 10px;
    font-weight: bold;
    border-bottom: 1px solid var(--light-color-gray-10);
  }

  &__beads {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 60vh;
    padding-top: 15px;
    overflow: auto;
  }

  &__actions {
    display: flex;
    gap: 10px;
    padding-top: 15px;
  }

  &__backdrop {
    position: fixed;
    top: -100vh;
    right: -100vw;
    bottom: -100vh;
    left: -100vw;
    z-index: -10;
  }
}

.close-button {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 25px;
  height: 25px;
  padding: 15px 10px;
  color: var(--color-black);
}
