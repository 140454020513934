@import '@/styles/variables';

.experiment-list-item {
  display: block;
  border-radius: 20px;
  transition: .3s box-shadow;

  &:hover {
    box-shadow: 0 0 10px 0 var(--shadow-color);
  }

  &:focus-visible {
    outline: 2px solid var(--focus-color);
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__icons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }

  &__title {
    @include title-format;
    font-size: 25px;
    font-weight: normal;
  }

  &__number {
    font-size: 35px;
    line-height: 1.15;
    letter-spacing: -.25px;
  }

  &__group {
    position: relative;
    padding: 0 20px 0 0;

    &:not(:last-child)::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      content: '';
      background-color: var(--light-color-gray-10);
    }

    &_content {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      height: auto;
      overflow: inherit;
    }
  }
}
