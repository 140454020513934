.settings-block {
  position: relative;
  padding-right: 20px;
  padding-left: 0;

  &::after {
    position: absolute;
    top: 5px;
    right: 0;
    bottom: 5px;
    width: 1px;
    content: '';
    background-color: var(--light-color-gray-10);
  }

  &__title {
    padding-bottom: 15px;
    font-size: 17px;
    font-weight: 600;
    line-height: normal;
  }

  &__text-content {
    display: grid;
    grid-template-rows: 1fr;

    &_group {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      padding: 10px 0;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        content: '';
        background-color: var(--light-color-gray-10);
      }

      &_column {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }
  }

  &__item {
    word-break: break-word;

    &_no-item {
      color: var(--light-gray);
    }

    &_scroll {
      overflow: auto;
    }
  }
}
