.coords {
  --coord-bg-color: #272727;

  display: flex;
  gap: 5px;

  &__coordinate {
    padding: 4px 8px;
    font-size: 14px;
    color: var(--color-white);
    background-color: var(--coord-bg-color);
    border-radius: 15px;
  }
}
