.chart-card {
  border: 1px solid var(--light-color-gray-10) !important;

  &_no-data {
    cursor: not-allowed !important;
  }

  &__header {
    padding-bottom: 12px;
  }
}

.no-data-content {
  margin-top: -40px;
}

.flip-block {
  position: relative;
  transition: background-color .4s ease-out;

  &_updated::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
    background-color: var(--color-green-50) !important;
    opacity: .5;
  }
}

.updated-block {
  display: flex;
  column-gap: 5px;
  align-items: center;
  width: fit-content;
  padding: 2px 10px;
  margin-top: 50px;
  margin-left: 20px;
  line-height: 1;
  color: var(--color-white);
  background-color: var(--color-green-10);
  border-radius: 10px;
}
