.sorted-field-heading {
  position: relative;
  display: inline-flex;
  align-items: center;

  &__label {
    position: relative;
    cursor: pointer;
  }

  &__arrow {
    --sorting-icon-bg-color: var(--light-gray);

    position: relative;
    width: 20px;
    height: 20px;
    padding: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s ease-out, transform .3s ease-out;

    &_absolute {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform-origin: top;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      content: '';
      border-top: 10px solid var(--sorting-icon-bg-color);
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      transform: translate(-50%, -50%);
    }

    &::after {
      position: absolute;
      top: calc(50% - 2px);
      left: 50%;
      width: 0;
      height: 0;
      content: '';
      border-top: 7px solid var(--color-white-20);
      border-right: 3px solid transparent;
      border-left: 3px solid transparent;
      opacity: 0;
      transition: opacity .3s ease-out;
      transform: translate(-50%, -50%);
    }

    &_current._asc,
    &_current._dec {
      opacity: 1;

      &::after {
        opacity: 0 !important;
        transition: opacity .3s ease-out !important;
      }
    }

    &_current._dec {
      rotate: 180deg;
    }
  }

  &__label:hover + &__arrow {
    opacity: 1;

    &::after {
      opacity: 1;
      transition: opacity 0s ease-out;
    }
  }
}
