@import '@/styles/variables';

.instrument {
  &__title-block {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: solid 1px var(--color-black-20);
  }

  &__name {
    @include title-format;
    @include resizeLinearMd(font-size, 26, 32);
    font-size: 32px;
    font-weight: bold;
  }

  &__details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 0 0;
  }

  &__details-block {
    position: relative;
    padding: 0 25px;

    &::before {
      position: absolute;
      left: 0;
      width: 1px;
      height: 100%;
      content: '';
      background-color: var(--color-black-20);
    }

    &:first-child {
      padding-left: 0;

      &::before {
        background-color: unset;
      }
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 50px;
    align-items: center;
    padding: 2px 0;
    font-size: 17px;

    & span {
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
    }
  }

  &__column-content {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__status {
    justify-self: start;
  }

  &__version {
    max-width: min(500px, 50vw);

    &_wide {
      max-width: max(500px, 60vw);
    }
  }

  &__version-block {
    flex-basis: min-content;
    min-width: 300px;
  }
}
