@import '@/styles/variables';

.popover {
  @include box-shadow;
  position: absolute;
  z-index: 10;
  width: 320px;
  padding: 20px;
  background-color: var(--color-white);
  border-radius: 10px;
  transition: all .2s ease-in;

  &_opened {
    animation: backdrop-show .3s ease-out;
  }

  &_closed {
    animation: backdrop-hide .3s ease-out;
  }

  &__title {
    padding-bottom: 10px;
    border-bottom: solid 1px var(--color-white-5);
  }

  &__content_gaped {
    padding-top: 10px;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
}

@keyframes backdrop-show {
  from {
    top: 10px;
    opacity: 0;
  }

  to {
    top: 20px;
    opacity: 1;
  }
}

@keyframes backdrop-hide {
  from {
    top: 20px;
    opacity: 1;
  }

  to {
    top: 10px;
    opacity: 0;
  }
}
