@font-face {
  font-family: SFMono;
  font-style: normal;
  font-weight: bold;
  src: url('SFMono-Bold.woff2') format('woff2'), url('SFMono-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: SFMono;
  font-style: normal;
  font-weight: 600;
  src: url('SFMono-Semibold.woff') format('woff2'), url('SFMono-Semibold.woff2') format('woff');
  font-display: swap;
}

@font-face {
  font-family: SFMono;
  font-style: normal;
  font-weight: 500;
  src: url('SFMono-Medium.woff') format('woff2'), url('SFMono-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: SFMono;
  font-style: normal;
  font-weight: normal;
  src: url('SFMono-Regular.woff2') format('woff2'), url('SFMono-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: SFMono;
  font-style: normal;
  font-weight: 300;
  src: url('SFMono-Light.woff') format('woff2'), url('SFMono-Light.woff2') format('woff');
  font-display: swap;
}
