.dataset-list-modal {
  top: 0;
  right: 0;
  max-width: 40vw;
  height: 100vh;

  &__content {
    padding-top: 0;
  }

  &__footer {
    gap: 10px;
    justify-content: flex-end;
    padding: 20px 0 0;
  }

  &__button {
    width: fit-content;
  }
}


