.downloads {
  &__file-icon {
    display: inline-block;
    width: auto;
    height: 40px;
    vertical-align: middle;
  }

  &__button {
    width: 40px;
    height: 40px;
    padding: 10px;
    color: var(--color-black);
    border-color: var(--light-color-gray-10);
    border-width: 1px;
  }
}
