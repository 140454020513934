
.render-content-animation {
  opacity: 0;
}

.in-view {
  &__placeholder {
    background-color: var(--color-white);
    border-radius: var(--theme-panel-border-radius, 20px);
  }

  &__content {
    height: 100%;
  }
  
  .render-content-animation {
    animation: fade-in .5s forwards;
  }
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}
