@import '@/styles/variables';
@import '@/styles/badge';

.card {
  display: block;
  height: 100%;
  min-height: 305px;
  cursor: pointer;
  border-radius: 20px;
  transition: .3s box-shadow;

  &:hover {
    box-shadow: 0 0 10px 0 var(--shadow-color);
  }

  &:focus-visible {
    outline: 2px solid var(--focus-color);
  }

  &__panel {
    height: 100%;
  }

  &__content {
    position: relative;
    display: flex;
    flex: 1;
    gap: 10px;
    justify-content: space-between;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      content: '';
      background-color: var(--color-black-20);
    }
  }

  &__content-main {
    flex-grow: 1;
  }

  &__title-wrap {
    margin-bottom: 15px;
  }

  &__title {
    @include title-format;
    @include resizeLinearMd(font-size, 20, 33);
    font-size: 33px;
    font-weight: 600;
  }

  &__content-aside {
    display: flex;
    justify-content: center;
    min-width: 100px;
    padding-left: 20px;
  }

  &__content-divider {
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0;
      height: 90%;
      content: "";
      border-left: 1px solid var(--light-color-gray-10);
    }
  }

  &__status {
    justify-self: start;
  }

  &__status-icon {
    @include statusColorForSVG;
  }

  &__content-row {
    display: grid;
    grid-template-columns: 110px auto;
    gap: 20px;
    max-width: 100%;
    padding-bottom: 5px;
    font-size: 20px;
    word-break: break-word;
  }

  &__run-name {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }

  &__meta {
    width: 100%;
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    color: var(--color-black);
    letter-spacing: -.25px;
  }

  &__meta-row {
    display: grid;
    grid-template-columns: 110px 1fr;
    gap: 20px;

    &>span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
