.dataset {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__divider {
    margin: 20px 0;
    border: none;
    border-top: 1px solid var(--light-color-gray-10);
  }
}
