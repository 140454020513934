@mixin experiment-data-control-button {
  --control-item-size: 35px;
  --control-item-color: var(--light-color-gray-15);
  --control-item-bg-color: var(--color-white-20);

  width: var(--control-item-size);
  height: var(--control-item-size);
  padding: 5px;
  line-height: 1;
  color: var(--control-item-color);
  background-color: var(--control-item-bg-color);
  border-radius: 10px;
  transition: all .3s ease-out;

  &:hover {
    --control-item-color: var(--color-black);
    --control-item-bg-color: var(--color-yellow-10);
  }
}
