.header {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--light-color-gray-10);

  &__label {
    margin-bottom: -5px;
  }

  &__status-info {
    font-size: 20px;
    font-weight: bold;
  }

  &__step-info {
    font-size: 20px;
    color: var(--light-color-gray-15);
  }
}

.step-wrapper {
  display: flex;
  justify-content: space-between;
}
