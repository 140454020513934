@import '@/styles/variables';

.custom-ranges {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 310px;
  overflow: auto;

  &__popover {
    @include box-shadow;
    width: 600px;
    padding: 15px;
    background-color: var(--color-white);
    border-radius: 10px;
  }

  &__bottom-space {
    padding-bottom: 15px;
  }

  &__new-wrap {
    border-top: 1px solid var(--light-gray);
  }

  &__new-range {
    margin-top: 15px;
  }
}

.custom-range {
  display: grid;
  grid-template-columns: 1fr 2fr .2fr;
  gap: 5px;
  align-items: center;
  width: 100%;
  padding: 5px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color .3s ease-out;

  &:hover {
    background-color: var(--color-yellow-50);
  }

  &_selected {
    background-color: var(--color-yellow-40);
  }

  &__input-wrap {
    display: flex;
    grid-template-columns: var(--width-left) var(--width-right);
  }

  &__input {
    border: 1px solid var(--light-gray);
  }

  &__text {
    min-width: unset;
    height: 25px;
  }

  &__title {
    flex-grow: 1;
  }

  &__axes {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 2px;
    justify-content: center;
    padding-inline: 10px;
  }

  &__axis {
    display: grid;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__action {
    width: 30px;
    height: 30px;
    padding: 5px;
    color: var(--color-gray-20);
    border-radius: 5px;
  }
}

.fieldset {
  position: relative;
  padding: 0;
  font-size: 14px;
  border: 1px solid var(--light-gray);
  border-radius: 7px;

  &_min {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &_max {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }  
}

.log {
  &::after {
    position: absolute;
    bottom: -0.5em;
    left: -0.2em;
    content: "10";
    
  }

  &::before {
    position: absolute;
    bottom: -0.4em;
    left: -0.2em;
    width: 1.2em;
    height: 1.2em;
    content: "";
    background-color: var(--color-white);
  }
}

.axis-input {
  height: 30px;
  border: none;
}

.axis-input-wrapper {
  margin-top: -10px;
}

.legend {
  margin-left: 10px;
  font-size: 15px;
  color: var(--light-gray);
}

.ranges-buttons {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-top: 15px;
}

.custom-ranges-button {
  display: grid;
  grid-template-columns: 10px 1fr 10px;
  align-items: center;
  width: 100%;
}

.error {
  border-color: var(--color-red);
  border-width: 2px;
}

.single-custom-range {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  border: 1px solid var(--icons-border-color);
  border-radius: 10px;
}
