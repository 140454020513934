.not-found {
  display: flex;
  flex-grow: 1;
  gap: 20px;
  align-items: center;
  justify-content: center;

  &__code {
    font-size: 72px;
    cursor: default;
  }

  &__link {
    color: var(--color-gray-20);
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition: color .3s var(--cubic-ease-out-expo);

    &:hover {
      color: var(--color-gray);
      border-bottom: 2px solid currentcolor;
    }
  }
}
