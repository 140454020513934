.totp-modal {
  width: var(--amplify-components-authenticator-container-width-max);
  padding: var(--amplify-components-authenticator-form-padding);
  
  &__header {
    padding-bottom: unset;
  }

  &__content {
    padding-top: unset;
  }

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    align-items: center;
    justify-content: flex-start;
  }

  &__footer {
    flex-direction: column;
    gap: 10px;
    align-items: center;
    padding-top: 10px;
  }
}
