@import '@/styles/variables';
@import '@/styles/experimentDataControlButton';

.sequencing-data {
  --title-column-width: 130px;
  --form-grid-gap: 5px;

  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  width: 100%;

  &__control-container {
    display: grid;
    flex: 1;
    grid-template-rows: auto;
    grid-template-columns: var(--title-column-width) repeat(2, 1fr);
    gap: var(--form-grid-gap);
    align-items: center;
  }

  &__column1 {
    grid-column: 1;
  }

  &__column2 {
    grid-column: 2;
  }

  &__column3 {
    grid-column: 3;
  }

  &__column4 {
    grid-column: 4;
  }

  &__selector {
    min-width: 0; // Grid items have an initial size of min-width: auto
    background-color: var(--color-white);
    border: solid 2px var(--color-white-20);
    border-radius: 10px;

    &_with-gap {
      margin-top: 10px;
    }
  }

  &__selector-control {
    color: var(--light-color-gray-10);

    &:hover {
      color: var(--color-gray-70);
    }
  }

  &__selector-menu-list {
    &#{&} {
      max-height: 375px;
    }
  }

  &__process-button {
    width: fit-content;
    height: 45px;
    background-color: var(--color-white);

    &_force-process {
      width: 45px;
      margin-left: auto;
      padding-inline: 7px;
    }
  }

  &__buttons {
    display: flex;
    gap: 5px;
    align-items: center;
    align-self: flex-start;

    &:last-child {
      flex-basis: 100%;
      width: 100%;
    }

    &_process {
      margin-left: calc(var(--title-column-width) + var(--form-grid-gap));
    }
  }

  &__reads-button {
    align-self: flex-start;
    width: 44px;
    height: 44px;
    padding: 2px;
  }

  &__buttons-container {
    display: flex;
    gap: 4px;
  }

  &__control-button {
    @include experiment-data-control-button;
    cursor: pointer;

    &_info {
      font-family: SFMono, Arial, Helvetica, sans-serif;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      cursor: default;
    }
  }
}
