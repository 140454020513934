@import '@/styles/variables';

.datasets-filters {
  --datasets-filters-color: var(--theme-datasets-filters-color, var(--light-gray));
  --theme-checkbox-border-color: var(--color-black-5);
  --theme-checkbox-border-color-disabled: var(--color-black-5);
  --theme-checkbox-label-color-disabled: var(--color-black-5);

  &__panel {
    display: flex;
    flex: 1;
    flex-direction: row;
    column-gap: 15px;
    align-items: center;
  }

  &__title {
    flex-shrink: 0;
    color: var(--datasets-filters-color);
    text-align: center;
  }

  &__groups-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
}

.channel-check {
  --bg-color: var(--color-bf);

  padding: 20px;
  cursor: pointer;
  background-color: var(--bg-color);
  border: 1px solid var(--light-color-gray-5);
  border-radius: 10px;
}

.channel-disabled {
  --bg-color: var(--color-bf);
  padding: 9px 15px;
  background-color: var(--bg-color);
  border-radius: 10px;
  opacity: .4;
}

.filter-group {
  display: grid;
  grid-template-columns: 125px 1fr;
  align-items: center;

  &__title {
    color: var(--datasets-filters-color);
  }

  &__content {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    user-select: none;
  }

  &__time-points {
    gap: 10px 20px;
    padding: 10px 20px;
    overflow-x: auto;
    background-color: var(--color-white-15);
    border-radius: 10px;
  }

  &__time-point {
    height: auto;

    small {
      display: block;
      line-height: 1;
    }

    &_disabled {
      opacity: .4;
    }
  }
}
