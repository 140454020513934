@import '@/styles/variables';

.loader {
  --min-gap: 1px;
  --progress-top-gap: 150px;

  @include loader;

  height: 100%;
  overflow: hidden;

  &__progress {
    position: sticky;
    top: var(--progress-top-gap);
  }

  &__analysis-wrapper {
    justify-content: flex-start;
  }

  &__dataset-wrapper {
    height: calc(100% - var(--min-gap));
  }
}

.datasets-list {
  --border-colour: var(--light-color-gray-10);
  --header-item-color: var(--light-color-gray-10);
  --header-item-color-selected: var(--color-black-20);
  --selected-number-color: var(--color-yellow-40);
  --number-color: var(--color-white-5);
  --min-gap: 1px;

  position: sticky;
  top: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - var(--min-gap));
  overflow: hidden;

  &__header {
    &#{&} {
      display: flex;
      gap: 20px;
      padding: 0 20px;
      cursor: default;
    }
  }

  &__text {
    padding-top: 10px;
  }

  &__gates {
    --gates-height-gap: 50px;
    position: relative;
    display: none;
    height: 100%;
    max-height: calc(100% - var(--gates-height-gap));
    padding: 0 10px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__gates-with-datasets {
    --gates-with-datasets-height-gap: 85px;

    max-height: calc(100% - var(--gates-with-datasets-height-gap));
  }

  &__gates-items {
    position: absolute;
    width: calc(100% - 20px);
  }

  &__gates-loading {
    --min-gap: 1px;

    height: calc(100% - var(--min-gap));
    overflow-y: hidden;
  }

  :global(.react-tabs__tab-panel--selected) {
    display: block !important;
  }
}

.header__tabs {
  height: 100%;
}

.header-item {
  $root: &;

  box-sizing: content-box;
  display: flex;
  align-items: center;
  width: calc(100% / 2);
  padding-bottom: 10px;
  cursor: pointer;
  border-bottom: 1px solid var(--header-item-color);
  transition: border-color .3s ease-out;

  &:hover {
    #{$root}__title {
      color: var(--color-black);
      transition: color .3s ease-out;
    }
  }

  &_selected {
    font-weight: 600;
    border-color: var(--header-item-color-selected);
    outline: none;

    #{$root}__title {
      color: var(--color-black);
    }

    #{$root}__number {
      font-weight: normal;
      background-color: var(--selected-number-color);
      border-color: var(--selected-number-color);
    }

    &::after {
      display: block;
      height: 1px;
      overflow: hidden;
      font-weight: bold;
      color: transparent;
      visibility: hidden;
      content: attr(title);
    }
  }

  &__images-container {
    overflow: scroll;
  }

  &__title,
  & span {
    margin-right: 6px;
    font-size: 17px;
  }

  &__title {
    color: var(--light-gray);
  }

  &__title_gates {
    width: 45px;
  }

  &__title_images {
    width: 55px;
  }

  &__number {
    padding: 2px 10px;
    text-align: center;
    border: 1px solid var(--number-color);
    border-radius: 23px;
    transition: background-color .3s ease-out, border-color .3s ease-out;
  }
}

.select {
  padding: 0 20px 0 10px;

  &__option {
    padding-top: 1px;
    margin-left: -2px;
  }
}

.images-list {
  --images-height-gap: 35px;
  display: none;
  height: calc(100% - var(--images-height-gap));
}

.skeleton {
  --offset-x: 40px;
  width: calc(100% - var(--offset-x));
  height: 100%;
  padding: 20px;
  margin-top: 20px;
  margin-left: 20px;
}

.selected-gate {
  width: calc(100% - 25px);
  padding: 10px;
  margin-left: 10px;
  background-color: var(--color-white-20);
  border-radius: 10px;
}
