@import '@/styles/dashboard';

.dashboard {
  @include dashboard;
}

.control-panel {
  &__search {
    min-width: 350px;
  }

  &__button {
    width: 45px;
    height: 45px;
    padding: 0;
  }
}
