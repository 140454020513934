.flip-block {
  z-index: var(--z-flip-block);
  height: 100%;

  &_full-screen {
    position: fixed;
    inset: 20px;
    height: initial;
    box-shadow: 0 1px 11px 0 rgba(34, 60, 80, .2);
  }

  &__background {
    position: relative;
    z-index: -110;
    pointer-events: none;
    opacity: 0;
    transition: opacity .2s ease-out;

    &::before {
      position: fixed;
      inset: 0;
      content: '';
      background-color: rgba(#000, .5);
    }

    &_visible {
      z-index: calc(var(--z-flip-block) + var(--z-below));
      pointer-events: all;
      opacity: 1;
    }
  }
}

