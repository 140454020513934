.knee-plot {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;

  &__skeleton {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  &__controls {
    display: flex;
    gap: 5px;
    align-items: center;
    width: max-content;
    height: 45px;
    margin: 10px 0;
  }

  &__container {
    flex-grow: 1;
    overflow: hidden;
  }

  &__chart {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }
}
