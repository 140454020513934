@import '@/styles/variables';
@import '../../variables';

.sensor {
  @include item-bottom-border;

  display: inline-grid; // fix Safari columns issue
  grid-template-columns: 1fr 150px;
  gap: 10px;
  width: 100%;
  padding-block: 10px;
  padding-inline-end: 10vw;
  font-size: 17px;
  font-weight: normal;
  letter-spacing: 0.25px;
  transition: border-bottom 0.3s;

  &_clickable:hover {
    border-bottom: solid 1px var(--color-black);
  }

  &_disabled {
    pointer-events: none;
    cursor: default;
    opacity: .6;
  }

  &__name {
    @include list-item-name;

    &_dashed {
      text-decoration-style: dashed;
      cursor: default;
    }

    &_selected {
      font-weight: bold;
      color: var(--color-black);
      text-decoration: none;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &__time {
    padding-right: 5px;
    font-size: 0.8em;
    line-height: 1.6;
    color: var(--light-gray);
    text-align: right;
  }
}
