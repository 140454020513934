.project-list-item {
  --icon-opacity: 0;

  position: relative;
  display: grid;
  grid-template-columns: var(--grid-layout);
  padding-inline: 20px;
  gap: 20px;
  min-height: 70px;
  cursor: pointer;

  &::before {
    position: absolute;
    top: 0;
    right: 20px;
    left: 20px;
    content: '';
    border-bottom: 1px solid var(--light-color-gray-10);
  }

  &:hover {
    --icon-opacity: 1;
  }

  &__column {
    display: flex;
    align-items: center;

    &_owner {
      gap: 8px;
      justify-content: flex-start;
    }

    &_team {
      --member-list-justify: flex-end;
      padding-left: 10px;
    }

    &_icon-wrap {
      position: absolute;
      right: 20px;
      bottom: 20px;
      opacity: var(--icon-opacity);
      transition: opacity .3s ease-out;
    }
  }
}
