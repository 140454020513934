@import '@/styles/variables';

.loader {
  @include loader;
  height: 100vh;
  background: none;
}

.layout {
  display: grid;
  min-height: 100vh;
  padding-left: var(--page-sidebar-width);

  &__aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: var(--z-header);
  }

  &__main {
    display: flex;
    flex-direction: column;
    min-width: calc(1366px - var(--page-sidebar-width));
    padding: 0 20px 65px;
  }

  &__content {
    position: relative;
    flex: 1;
    padding-bottom: 20px;
  }
}
