@import '@/styles/variables';

.popover {
  @include box-shadow;
  width: 250px;
  background-color: var(--color-white);
  border-radius: 10px;
}

.range-container {
  .range {
    --range-handlers-width: 40px;
    width: calc(100% - var(--range-handlers-width));
    margin: auto;

    &__input {
      width: 100px;
      border: solid 1.5px var(--icons-border-color);
      border-radius: 10px;
    }
  }
}

.button {
  margin-top: 20px;
}
