.attachment-modal {
  --modal-width: 650px;
  --modal-height: 400px;

  width: var(--modal-width);
  height: var(--modal-height);
  overflow: auto;

  &__button {
    font-size: 17px;
    font-weight: normal;

    & button {
      height: 45px;
    }
  }

  &__footer {
    display: flex;
    column-gap: 5px;
    justify-content: space-between;
  }

  &__select-container {
    width: 100%;
  }

  &__select-menu {
    --menu-max-width: 610px;

    max-width: var(--menu-max-width) !important;
  }

  &__select-menu-list {
    --menu-list-height: 240px;

    max-height: var(--menu-list-height) !important;
  }

  &__selector {
    background-color: var(--color-white);
    border: solid 2px var(--color-white-20);
    border-radius: 10px;

    &:hover {
      border: solid 2px var(--light-color-gray-5);
    }
  }
}
