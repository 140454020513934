.main-controllers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  height: max-content;
  overflow: hidden;
  background-color: var(--color-white-20);
  border-radius: 10px;
}

.control__icon {
  height: max-content;
}

.gate-import-control {
  color: var(--color-black);
  border-left: solid 1px var(--icons-border-color);
}
