.image-modal {
  --border-color: var(--light-color-gray-10);
  --time-color: var(--color-black);
  --loading-display: block;

  &__content {
    padding-top: 0;
  }

  &__header {
    position: relative;
    border-color: var(--color-black);
  }

  &__header-controls {
    position: absolute;
    top: 0;
    right: 60px;
    display: flex;
    gap: 4px;
  }

  &__header-button {
    --arrow-angle: -45deg;
    --arrow-offset: 2px;
    --arrow-color: var(--color-black);

    position: relative;
    width: 40px;
    height: 30px;
    padding: 0;
    background-color: var(--color-yellow);
    border: 2px solid transparent;
    transition: background-color .3s ease-out, border-color .3s ease-out;

    &::before {
      position: absolute;
      top: 50%;
      left: calc(50% + var(--arrow-offset));
      width: 12px;
      height: 12px;
      content: '';
      border-top: 2px solid var(--arrow-color);
      border-left: 2px solid var(--arrow-color);
      transform: translate(-50%, -50%) rotate(var(--arrow-angle));
    }

    &:hover:enabled {
      --arrow-color: var(--color-black);
      background-color: var(--color-yellow-40);
      border-color: var(--color-yellow);
    }

    &_next {
      --arrow-angle: 135deg;
      --arrow-offset: -2px;
    }
  }

  &__color {
    --color: var(--color-white-20);

    display: inline-block;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    background-color: var(--color);
    border-radius: 50%;
  }

  &__lut {
    display: inline-block;
    width: 36px;
    height: 18px;
    overflow: hidden;
    border-radius: 9px;

    &_disabled {
      filter: saturate(.1);
    }
  }

  &__lut-image {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__color-text {
    --disabled-color: var(--icons-border-color);

    &_disabled {
      color: var(--disabled-color);
    }
  }

  &__channel {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__functions {
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 8px;
    margin-bottom: 20px;
    background-color: var(--color-white);
  }

  &__actions {
    flex-shrink: 0;
    margin-left: 10px;
  }

  &__line-loader {
    position: absolute;
    bottom: 0;
  }

  &__text-container {
    display: flex;
    gap: 20px;
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(var(--image-columns, 4), minmax(0, 1fr));
    gap: 20px;
  }

  &__time {
    font-size: 14px;
    font-weight: 500;
    color: var(--time-color);
  }

  &__container {
    display: flex;
    gap: 20px;
    align-items: center;
    overflow-x: auto;
  }

  &__button {
    gap: 10px;
    width: initial;
    padding: 10px;
    color: var(--color-black);
  }

  &__icon {
    width: 17px;
    height: 17px;
  }
}

.section {
  --border-color: var(--light-color-gray-15);

  position: relative;

  &::after {
    position: absolute;
    width: 1px;
    content: '';
    background-color: var(--border-color);
  }

  &_first {
    padding-right: 20px;

    &::after {
      right: 0;
      height: 100%;
    }
  }

  &_second {
    margin-right: 18px;

    &::after {
      top: 10px;
      right: -12px;
      height: calc(100% - 22px);
    }
  }
}
