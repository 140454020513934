.scan-table {
  border-bottom: 1px solid var(--light-color-gray-10);

  &_minimal {
    border-bottom: none;
  }

  &:last-child {
    border-bottom: none;
  }

  &__accordion {
    padding: 0;
  }

  .table__col {
    overflow: hidden;
    font-size: 17px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.41;
    color: var(--color-black);
    text-overflow: ellipsis;
    letter-spacing: normal;
    white-space: nowrap;
    padding-block: 10px;

    &:first-child {
      padding-left: 10px;
      border-radius: 6px;
    }

    &_row {
      display: flex;
      gap: 10px;
      overflow: initial;
      padding-block: 0;
    }

    &_no-edit {
      overflow: hidden;
    }
  }

  .table__row {
    display: grid;
    grid-template-columns: var(--dataset-grid-template-columns);
    gap: 20px;
    align-items: center;
    padding: 10px 0;

    &_edit {
      .table__col_sample-name {
        background-color: var(--color-white-20);
      }
    }
  }

  .lane-info__row .table__col:first-child {
    grid-column: 2 / 3;
  }
}
