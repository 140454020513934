@import '@/styles/variables';

.table {
  --table-background-color: #f1f1f1;
  $root: &;

  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  border-collapse: collapse;
  border-block: 1px solid var(--light-color-gray-10);

  &__row {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      content: '';
      background-color: var(--light-color-gray-10);
    }

    &:nth-child(even) {
      background-color: var(--table-background-color);
    }

    &_no-underline {
      &::after {
        display: none;
      }
    }
  }

  &__head {
    background-color: var(--table-background-color);
  }

  &__heading, &__data-element {
    padding: 10px;
    border-left: 1px solid var(--light-color-gray-10);

    &:last-child {
      border-right: 1px solid var(--light-color-gray-10);
    }
  }

  &__data-element {
    &_status, &_center {
      text-align: center;
    }

    &_url {
      height: auto;
      padding: 0;
    }

    &_ok {
      background-color: var(--color-green-50);
    }

    &_error {
      background-color: var(--color-red-50);
    }
  }

  &__heading {
    text-transform: capitalize;
  }

  &__link-text {
    padding-right: 3px;
  }

  &__no-data {
    text-align:center;
    vertical-align:middle;
  }

  &__link {
    @include default-link;

    display: inline-block;
    width: 200px;
    height: 54px;
    padding: 0 10px;
    overflow: hidden;
    line-height: 54px;
    text-align:center;
    text-decoration: none;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
    vertical-align:middle;
    transition: color .1s ease-out;
  }
}
