@mixin list-item-name {
  align-self: baseline;
  width: max-content;
  color: var(--light-gray);
  text-align: left;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}

@mixin header-bottom-border {
  border-bottom: solid 1px var(--color-black);
}

@mixin item-bottom-border {
  border-bottom: solid 1px var(--light-color-gray-10);
}
