.popover-button {
  display: grid;
  place-content: center;
  width: 45px;
  height: 45px;
  cursor: pointer;
  background-color: var(--color-white-20);
  border-radius: 10px;

  &_active,
  &:hover:enabled {
    background-color: var(--color-yellow-10);
    border-color: var(--color-yellow-10);
  }
}
