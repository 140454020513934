.chart {
  --modebar-height: 45px;
  --padding-gap: 20px;
  --header-height: 50px;

  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: calc(100% - var(--header-height));
  padding-top: var(--padding-gap);

  &__no-data {
    --card-header-height: 45px;
    --left-axis-width: 25px;

    position: absolute;
    top: calc(50% - var(--card-header-height));
    left: calc(50% + var(--left-axis-width));
    width: 80%;
    white-space: pre-wrap;
    user-select: none;
    transform: translateX(-50%);
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.skeleton {
  position: absolute;
  left: 0;
  z-index: 2;
  width: 100%;
  height: calc(100% - var(--padding-gap));
}

.modebar {
  display: flex;
  gap: 5px;
  width: max-content;
  height: 45px;
}

.plot-chart {
  position: relative;
  width: 100%;
  height: 100%;
}

.plot {
  --aside-control-bar-width: 45px;
  --plotly-right-gap: 20px;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - var(--plotly-right-gap));
  height: calc(100% - var(--modebar-height));

  &__container {
    height: 100%;
    overflow: hidden;
  }
}
