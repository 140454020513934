.gates {
  display: flex;
  flex-direction: column;
  width: var(--sidebar-width);
  height: 100%;
  padding-left: 20px;
  overflow: hidden;

  &__header {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--light-color-gray-10);
  }

  &__title {
    font-size: 17px;
    font-weight: 600;
    line-height: 30px;
  }

  &__count-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: var(--color-yellow-40);
    border-radius: 50%;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: auto;
    padding-block: 15px;

    &_border {
      border-bottom: 1px solid var(--light-color-gray-10);
    }
  }

  &_expand {
    padding-right: 25px;
  }

  &__all-datasets {
    --theme-checkbox-label-color: var(--light-color-gray-15);
    width: fit-content;
    margin: 10px 20px;

    &:hover {
      --theme-checkbox-label-color: var(--color-black);
      --theme-checkbox-border-color: var(--color-black);
    }

    &_checked {
      --theme-checkbox-label-color: var(--color-black);
    }
  }
}
