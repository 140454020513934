.datasets-options {
  margin-bottom: 20px;
  line-height: normal;

  &__paner-wrapper {
    flex-direction: row;
    gap: 0;
  }

  &__panel {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
  }

  &__assay-name {
    font-size: 28px;
  }

  &__panel_detailed {
    padding-top: 20px;
    border-top: solid 1px var(--icons-border-color);
  }

  &__button {
    width: 45px;
    height: 45px;
    padding: 0;
  }

  &__label {
    color: var(--color-gray-70);

    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  &__filters {
    padding-left: 40px;
    border-left: 1px solid var(--icons-border-color);
  }

  &__viewas,
  &__assay {
    padding-right: 40px;
    padding-left: 20px;
  }
}

.viewas {
  &__label {
    flex-shrink: 0;
    padding-bottom: 2px;
    font-size: 17px;
    line-height: 1;
    color: var(--light-gray);
  }
}
