.axis-select {
  position: relative;
  z-index: 1;
  align-self: center;
  color: var(--light-color-gray-15);

  &_x {
    bottom: 5px;
  }

  &_y {
    left: -2px;
    align-self: center;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }

  &_y &__main {
    top: 0;
    left: 10px;
    width: max-content;
    writing-mode: horizontal-tb;
    transform: rotate(180deg) translate(100%, 25%);
  }

  &_y &__icon {
    margin-top: 5px;
    writing-mode: vertical-lr;
    transform: rotate(90deg) translateY(6px);

    &_reverted {
      transform: rotate(270deg);
    }
  }

  &__button {
    width: max-content;
    height: max-content;
    font-size: 17px;
    font-weight: normal;
    background: unset;
    border: unset;

    &:hover:enabled {
      background: unset;
      border: unset;
    }
  }

  &__icon {
    margin-top: 2px;
    margin-left: 5px;

    &_reverted {
      transform: rotate(180deg);
    }
  }

  &__main {
    position: absolute;
    bottom: 40px;
    width: max-content;
    min-width: 300px;
    height: max-content;
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  }

  &__menu {
    position: static !important; // important used for override react-select styles
  }

  &__menu-list {
    z-index: 1;
    box-shadow: none !important; // important used for override react-select styles
  }

  &__control {
    position: relative;
    padding-right: 10px;

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1px;
      content: "";
      background-color: var(--light-color-gray-10);
    }
  }

  &__selected {
    font-weight: 500;
    color: --var(--color-black);
  }
}
