.channel-assay {
  display: flex;
  gap: 10px;
  align-items: center;

  &__icons-set {
    margin-top: 0;
  }

  &__empty-assay {
    width: 35px;
    font-size: 17px;
    text-align: center;
  }

  &__select-option {
    display: flex;
    gap: 7px;
    align-items: center;
  }

  &__select {
    text-align: left;
    background-color: var(--color-white);
    border: 1px solid var(--light-color-gray-10);
    border-radius: 6px;
  }

  &__select-value-container {
    &#{&} {
      padding: 2px;
    }
  }

  &__select-placeholder {
    &#{&} {
      padding: 2px 6px;
    }
  }

  &__select-single-value {
    margin: 0;
    overflow: visible;
    font-size: 17px;
  }

  &__select-arrow {
    color: var(--light-gray);
  }
}
