@import '@/styles/variables';

@mixin badge {
  width: fit-content;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-black);
  letter-spacing: 0.5px;
  border-radius: 10px;

  &_yellow {
    background-color: var(--color-yellow-50);
  }

  &_blue {
    background-color: var(--color-blue-50);
  }

  &_red {
    background-color: var(--color-red-50);
  }

  &_green {
    background-color: var(--color-green-50);
  }

  &_gray {
    background-color: var(--light-color-gray-10);
  }

  &_black {
    color: var(--color-white);
    background-color: var(--color-black);
  }
}

@mixin statusColorForSVG {
  color: var(--color-white);

  &_yellow {
    color: var(--color-yellow-50);
  }

  &_blue {
    color: var(--color-blue-50);
  }

  &_red {
    color: var(--color-red-50);
  }

  &_green {
    color: var(--color-green-50);
  }

  &_gray {
    color: var(--light-color-gray-10);
  }

  &_black {
    color: var(--color-black);
  }
}
