.arrow-control {
  --size: 58px;
  --arrow-control-bg-color: #3f3f3f;
  --button-bg-color: #272727;

  &__wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
    border: 2px solid var(--arrow-control-bg-color);
    border-radius: 50%;
    transform: rotate(45deg);
    mask-image: radial-gradient(circle, transparent 21px, #000 0);

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 45px;
      height: 45px;
      content: '';
      background-color: var(--arrow-control-bg-color);
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__button {
    --arrow-color: var(--color-white);

    position: relative;
    width: var(--size);
    height: var(--size);
    cursor: pointer;
    background-color: var(--button-bg-color);
    outline: none;
    transition: background-color .3s ease-out;

    &:hover {
      --arrow-color: var(--color-black);
      background-color: var(--color-yellow-40);
    }

    &::before {
      position: absolute;
      top: calc(50% - 4px);
      left: calc(50% - 3px);
      content: '';
      border-right: 4px solid transparent;
      border-bottom: 6px solid var(--arrow-color);
      border-left: 4px solid transparent;
      transition: border-color .3s ease-out;
      transform: rotate(calc(var(--order, 0) * 90deg - 45deg));
    }

    &:first-child {
      --order: 0;
    }

    &:nth-child(2) {
      --order: 1;
    }

    &:nth-child(3) {
      --order: 3;
    }

    &:nth-child(4) {
      --order: 2;
    }
  }
}
