.controls {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 45px;

  &__checkbox-container {
    display: flex;
    align-items: center;
    height: 45px;
    padding: 0 15px;
    background-color: var(--color-white-20);
    border-radius: 10px;
  }
}
