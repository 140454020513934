@import '@/styles/variables';

.loader {
  @include loader;
  width: max-content;
  margin: auto;

  &__no-data {
    padding-top: 20px;
  }
}

.images-scroll-area {
  --label-color: #f5f5f5;
  --border-color: var(--light-color-gray-10);
  --page-headers-total-heigth: 70px;

  position: sticky;
  top: var(--page-headers-total-heigth);
  display: flex;
  flex-direction: column;
  min-width: 404px;
  height: 100%;
  max-height: calc(100vh - var(--page-headers-total-heigth));
  overflow: hidden;

  &__title {
    font-size: 17px;
    font-weight: 600;
    line-height: 30px;
  }

  &__label {
    width: max-content;
    height: 30px;
    padding: 3px 10px;
    line-height: 24px;
    background-color: var(--color-yellow-40);
    border-radius: 23px;
  }

  &__image-row {
    display: flex;
  }

  &__image-item {
    width: 64px;
  }

  &__images {
    height: 100%;
    padding: 0 20px;
    overflow: auto;
    scrollbar-gutter: stable;
  }

  &__images-container {
    position: absolute;
    width: 100%;
    height: calc(100% - 30px);
    overflow: hidden;
  }

  &__images-wrapper {
    position: relative;
    height: calc(100% - 20px);
  }

  &__header {
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 12px 20px;
    margin-bottom: 20px;

    &::after {
      position: absolute;
      bottom: 0;
      left: 20px;
      width: calc(100% - 40px);
      height: 1px;
      content: '';
      background-color: var(--border-color);
    }
  }
}
