.display-settings {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;

  &__visibility-icon {
    margin-left: 10px;

    &_disabled {
      opacity: .4;
    }
  }

  &__group-container {
    display: flex;
    gap: 24px;
  }

  &__loading-container {
    position: relative;
  }

  &__loading {
    position: absolute;
    top: -8px;
    left: 8px;
    width: 20px;
    height: 20px;
    border: 3px solid var(--color-gray);
    border-right: 3px solid var(--color-yellow);
    border-radius: 50%;
    opacity: .4;
    animation: spin 1.2s ease-out infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
