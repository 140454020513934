.popover {
  --option-color: #808080;
  --option-color-hover: var(--color-white);
  --list-bg-color: var(--color-black-20);
  --option-line-color: var(--color-gray-60);
  --backdrop-color: #1a1a1a;

  --light-option-color: #999;
  --light-option-color-hover: var(--color-black);
  --light-list-bg-color: var(--color-white);
  --light-option-line-color: var(--color-white-10);

  @keyframes backdrop-show {
    from {
      opacity: 0;
    }

    to {
      opacity: var(--opacity-max);
    }
  }

  @keyframes backdrop-hide {
    from {
      opacity: var(--opacity-max);
    }

    to {
      opacity: 0;
    }
  }

  &_light {
    --option-color: var(--light-option-color);
    --option-color-hover: var(--light-option-color-hover);
    --list-bg-color: var(--light-list-bg-color);
    --option-line-color: var(--light-option-line-color);
    --backdrop-color: none;
  }

  &__backdrop {
    --opacity-max: .4;

    position: fixed;
    top: -100vh;
    right: -100vw;
    bottom: -100vh;
    left: -100vw;
    background-color: var(--backdrop-color);
    opacity: .4;
    animation: backdrop-show .3s ease-out;
  }

  &_closing &__backdrop {
    opacity: 0;
    animation: backdrop-hide .3s ease-out;
  }

  &__wrap {
    --opacity-max: 1;

    display: flex;
    flex-direction: column;
    min-width: 220px;
    overflow: hidden;
    color: var(--option-color);
    background-color: var(--list-bg-color);
    border-radius: 10px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    animation: backdrop-show .3s ease-out;
  }

  &_closing &__wrap {
    opacity: 0;
    animation: backdrop-hide .3s ease-out;
  }

  &__option {
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: var(--list-bg-color);
    transition: color .3s ease-out;
    padding-inline: 16px;

    &:hover {
      color: var(--option-color-hover);
    }

    &::before {
      position: absolute;
      right: 16px;
      bottom: 0;
      left: 16px;
      content: '';
      border-bottom: 1px solid var(--option-line-color);
    }

    &:last-child::before {
      border-bottom: none;
    }

    &_disabled:hover {
      color: var(--option-color);
      cursor: not-allowed;
    }

    &_inactive:hover {
      color: var(--option-color);
      cursor: default;
    }
  }

  &__text {
    font-size: 17px;
    line-height: 45px;

    &_inactive {
      font-size: smaller;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }

  &__wrapper {
    width: max-content;
  }
}

.storybook {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;

  &__popover {
    --theme-popover-background-color: var(--color-black-20);
  }
}
