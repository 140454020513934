.icons-set {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;

  &__icon {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: calc(var(--size,45) * 1px);
    height: calc(var(--size,45) * 1px);
    border: calc(var(--border-width, 0) * 1px) solid currentcolor;
    border-radius: 10px;
    transition: background-color .3s ease-out, color .3s ease-out;

    &_yellow {
      color: var(--color-black-20);
      background-color: var(--color-yellow-40);
    }

    &_gray {
      color: var(--light-gray);
    }

    &_transparent {
      color: var(--color-black);
      background-color: transparent;
    }

    &_light-yellow-invert {
      color: var(--color-black);
      background-color: transparent;

      &::before,
      &::after {
        position: absolute;
        inset: 1px;
        content: '';
        border-radius: 10px;
        mix-blend-mode: difference;
      }

      &::before {
        background-color: var(--color-yellow-60);
      }

      &::after {
        background-color: var(--color-white);
      }
    }

    & > svg {
      z-index: 1;
    }
  }

  &__icon-border {
    --inset: 0;

    position: absolute;
    inset: var(--inset);
    z-index: 1;
    border: 2px solid var(--color-yellow-60);
    border-radius: inherit;

    /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx), (min-resolution: 192dpi) {
      // restyle for retina screen
      --inset: 0.5px;
      border: 1.5px solid var(--color-yellow-60);
    }
  }
}

.preprocessing {
  --width: 10px;
  --height: 10px;
  --right: -3px;
  --top: -3px;
  --border: 1px solid var(--color-white-20);
  position: absolute;
  top: var(--top);
  right: var(--right);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--width);
  height: var(--height);
  background-color: #66f0fb;
  border: var(--border);
  border-radius: 50%;

  &_with-icon {
    --right: -6px;
    --top: -6px;
    --width: 21px;
    --height: 21px;
    --border: 2px solid var(--color-white-20);
  }
}
