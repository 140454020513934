.color-picker {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: 1fr;
  gap: 2px;
  padding-top: 4px;
}

.color-button {
  display: inline-block;
  min-width: 5px;
  aspect-ratio: 1;
  cursor: pointer;
  border: 0;
  transition: border-radius .3s ease-out;

  &:hover {
    border-radius: 5px;
  }

  &_active {
    border-radius: 50%;

    &:hover {
      border-radius: 50%;
    }
  }

  &[disabled] {
    pointer-events: none;
    background-color: var(--color-white-5) !important;
  }
}

.lut-button {
  grid-column-start: span 2;
  padding: 0;
  margin: 0;
  overflow: hidden;
  border: none;
  outline: none;
  transition: border-radius .3s ease-out;

  &:hover {
    border-radius: 5px;
  }

  &_active {
    border-radius: 500px;

    &:hover {
      border-radius: 500px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &[disabled] &__image {
    pointer-events: none;
    filter: saturate(.1);
  }
}
