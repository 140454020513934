.content {
  --text-alignment: center;
  --font-size: 17px;

  width: 100%;
  font-size: var(--font-size);
  color: var(--light-gray);
  text-align: var(--text-alignment);
  word-break: break-word;
}
