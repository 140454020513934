@import '@/styles/variables';

.skeleton {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.no-data {
  @include no-data-block;
}

.timeline {
  position: relative;
  overflow: hidden;

  &__header {
    display: flex;
    gap: 35px;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 10px;
    line-height: 20px;
    border-bottom: solid 1px var(--light-color-gray-10);
  }

  &__header-items {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
  }

  &__header-titles {
    display: flex;
    flex-grow: 1;
    gap: 20px;
    align-items: center;
  }

  &__header-title {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 17px;
  }

  &__header-color-mark {
    width: 15px;
    height: 15px;
    background-color: var(--mark-color);
    border-radius: 10px;
  }

  &__header-button {
    width: 15px;
    height: 15px;
    padding: 0;
    color: var(--black-color);

    &:hover {
      --button-hover-background-color: unset;
      --button-hover-border-color: var(--color-white);
    }
  }

  &__content {
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 70px);
    overflow: hidden;
    margin-block: 15px;
  }

  &__chart {
    flex-grow: 1;
    height: 100%;
  }
}

.sensors-select {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 175px;
  height: 35px;
  text-align: center;
  border: 1px solid var(--icons-border-color);
  border-radius: 10px;

  &__placeholder {
    color: var(--color-black) !important;
  }
}

.y-axis {
  align-self: center;
  min-width: 20px;
  font-size: 16px;
  font-weight: normal;
  color: var(--light-gray);
  writing-mode: sideways-lr;
  

  &_right {
    writing-mode: sideways-rl;
  }
}
