.color-button {
  width: 25px;
  height: 25px;
  padding: 2px;
  color: var(--color-black-20);
  background: transparent;
  border-color: transparent;

  &_hidden {
    visibility: hidden;
  }

  &__color-circle {
    width: 15px;
    height: 15px;
    background-color: var(--color);
    border-radius: 50%;

    &_disabled {
      background-color: var(--light-gray);
    }
  }
}
