@import '@/styles/dashboard';
@import '@/styles/badge';

.dashboard {
  @include dashboard;
}

.badge {
  @include badge;

  margin: 0 auto;
}

.switch {
  --theme-checkbox-bg-color-slider-disabled: var(--color-gray-70);
  --theme-checkbox-bg-color-slider-checked-disabled: var(--color-gray-70);
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.small {
  font-size: 12px;
}

.header-actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.header-action {
  --button-font-size: 17px;

  width: fit-content;
  padding: 0 40px;
  font-weight: normal;
}

.remove-btn {
  --button-hover-background-color: var(--color-red-20) !important;
  --button-hover-border-color: var(--color-red-20) !important;

  &:hover:enabled {
    color: var(--color-white);
  }
}
