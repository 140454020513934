@import '@/styles/variables';

.popover {
  --g: radial-gradient(55% 15px at 50% 50%, #0001, #0000);

  position: relative;
  width: 320px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  background: linear-gradient(#fff 10px, #0000 40px calc(100% - 40px), #fff calc(100% - 10px)) local,
    var(--g) top / 100% 200%, var(--g) bottom/100% 200%;
  background-color: var(--color-white);
  border: 1px solid var(--color-white-20);
  border-radius: 10px;
  padding-inline: 20px;
}

.section {
  width: 100%;
  padding: 15px 0;

  & + & {
    border-top: solid 1px var(--icons-border-color);
  }

  &_multiple {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__head {
    --padding-bottom: 10px;
    --color: var(--light-gray);
    --font-size: 17px;

    padding-bottom: var(--padding-bottom);
    font-size: var(--font-size);
    color: var(--color);

    &_no-gap {
      --padding-bottom: 0;
    }

    &_small {
      --padding-bottom: 0;
      --color: var(--color-black);
      --font-size: 16px;
    }
  }

  &__content {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

  }

  &__select {
    flex-grow: 1;
    border: solid 1px var(--icons-border-color);
    border-radius: 10px;
  }

  &__inputs {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
  }

  &__input {
    display: flex;
    gap: 5px;
    align-items: center;

    input {
      border-color: var(--icons-border-color);
      border-width: 1px;
    }
  }

  &__buttons {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    &_small {
      width: 50px;
    }
  }
}

.compensation-select {
  width: 90%;
}

.select-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background-color: var(--color-white-20);
  border-radius: 10px;
}

.accordion {
  --accordion-button-padding: 0;

  svg {
    top: 0;
    right: 5px;
    width: 24px;
    height: 24px;
    color: var(--light-gray);
  }

  &__header {
    width: 100%;
    font-size: 17px;
    color: var(--light-gray);
    cursor: pointer;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: max-content;
    padding-top: 15px;
  }
}

.radio-btn {
  --radio-padding-left: 20px;
  --radio-width: 80px;
}

.colorscale {
  width: 50px;
  height: 20px;
  border-radius: 10px;
}

.current-colorscale {
  width: 100%;
  padding: 0;
}

.checkbox {
  justify-content: space-between;
  width: 100%;
}

.axes-scale-types-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;

  &_one-column {
    grid-template-columns: 1fr;
  }
}

.axis-scale-type {
  display: flex;
  gap: 5px;
  align-items: center;
}
