@import '@/styles/variables';

.chart {
  --modebar-height: 45px;
  --padding-gap: 20px;
  --header-height: 50px;

  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: calc(100% - var(--header-height));
  padding-top: var(--padding-gap);

  &__controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__no-data {
    --card-header-height: 45px;
    --left-axis-width: 25px;

    position: absolute;
    top: calc(50% - var(--card-header-height));
    left: calc(50% + var(--left-axis-width));
    width: 80%;
    user-select: none;
    transform: translateX(-50%);
  }
}

.skeleton {
  position: absolute;
  left: 0;
  z-index: 2;
  width: 100%;
  height: calc(100% - var(--padding-gap));
}

.modebar {
  display: flex;
  gap: 5px;
  width: max-content;
  height: 45px;
}

.popover {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
}

.plot {
  --aside-control-bar-width: 45px;
  --plotly-right-gap: 20px;
  --container-width-limitation: calc(var(--aside-control-bar-width) + var(--plotly-right-gap));

  @include chart-container-grid;
  position: relative;
  width: calc(100% - var(--container-width-limitation));
  height: calc(100% - var(--modebar-height));

  &__container {
    @include plotly-chart-grid-position;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__x-axis-label {
    @include x-axis-grid-position;
  }

  &__y-axis-label {
    @include y-axis-grid-position;
    left: -10px;
  }
}

.plot-chart {
  position: relative;
  width: 100%;
  height: 100%;
}
