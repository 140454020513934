@font-face {
  font-family: 'Inter Display';
  font-style: normal;
  font-weight: 900;
  src: url('InterDisplay-Black.woff2') format('woff2'), url('InterDisplay-Black.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: normal;
  font-weight: bold;
  src: url('InterDisplay-Bold.woff2') format('woff2'), url('InterDisplay-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: italic;
  font-weight: 900;
  src: url('InterDisplay-BlackItalic.woff2') format('woff2'), url('InterDisplay-BlackItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: normal;
  font-weight: 800;
  src: url('InterDisplay-ExtraBold.woff2') format('woff2'), url('InterDisplay-ExtraBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: italic;
  font-weight: 800;
  src: url('InterDisplay-ExtraBoldItalic.woff2') format('woff2'),
    url('InterDisplay-ExtraBoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: italic;
  font-weight: bold;
  src: url('InterDisplay-BoldItalic.woff2') format('woff2'), url('InterDisplay-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: italic;
  font-weight: normal;
  src: url('InterDisplay-Italic.woff2') format('woff2'), url('InterDisplay-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: italic;
  font-weight: 200;
  src: url('InterDisplay-ExtraLightItalic.woff2') format('woff2'),
    url('InterDisplay-ExtraLightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: normal;
  font-weight: 200;
  src: url('InterDisplay-ExtraLight.woff2') format('woff2'), url('InterDisplay-ExtraLight.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: normal;
  font-weight: 300;
  src: url('InterDisplay-Light.woff2') format('woff2'), url('InterDisplay-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: normal;
  font-weight: 500;
  src: url('InterDisplay-Medium.woff2') format('woff2'), url('InterDisplay-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: italic;
  font-weight: 500;
  src: url('InterDisplay-MediumItalic.woff2') format('woff2'), url('InterDisplay-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: italic;
  font-weight: 300;
  src: url('InterDisplay-LightItalic.woff2') format('woff2'), url('InterDisplay-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: normal;
  font-weight: 600;
  src: url('InterDisplay-SemiBold.woff2') format('woff2'), url('InterDisplay-SemiBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: italic;
  font-weight: 600;
  src: url('InterDisplay-SemiBoldItalic.woff2') format('woff2'), url('InterDisplay-SemiBoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: normal;
  font-weight: normal;
  src: url('InterDisplay-Regular.woff2') format('woff2'), url('InterDisplay-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: italic;
  font-weight: 100;
  src: url('InterDisplay-ThinItalic.woff2') format('woff2'), url('InterDisplay-ThinItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Display';
  font-style: normal;
  font-weight: 100;
  src: url('InterDisplay-Thin.woff2') format('woff2'), url('InterDisplay-Thin.woff') format('woff');
  font-display: swap;
}
