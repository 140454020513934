.nav {
  display: flex;
  gap: 5px;

  &__link {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    color: var(--color-black);
    text-transform: capitalize;
    background-color: var(--color-white);
    border-radius: 8px;
    transition: .3s background-color;

    &:focus-visible {
      outline: 2px solid var(--focus-color)
    }
  }

  &__slash-icon {
    width: 13px;
    height: 13px;
    margin-right: 1px;
    transform: translateY(1px);
  }
}
