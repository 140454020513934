.selected-dataset {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 50%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--light-color-gray-10);
    padding-block: 10px;
  }
}

.dataset-name {
  font-size: 20px;
  font-weight: bold;
}

.actions {
  display: flex;
  gap: 10px;
}

.action {
  display: flex;
  gap: 5px;
  align-items: center;
  width: max-content;
  height: 30px;
  border-radius: 50px;
}

.next-icon {
  transform: rotate(180deg);
}
