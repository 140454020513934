@mixin round-button {
  justify-self: center;
  width: 32px;
  height: 32px;
  padding: 5px;
  color: var(--color-black-5);
  background-color: var(--color-white-10);
  border-radius: 20px;
  transition: transform .3s;

  &:hover {
    background-color: var(--color-white-5);
  }
}
