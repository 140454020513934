@import '@/styles/variables';

.loader {
  @include loader;
}

.data {
  &__popup {
    position: fixed;
    bottom: 0;
    left: 85px;
    z-index: var(--z-data-popup);
    width: calc(100% - var(--page-sidebar-width));
    padding: 12px 20px;
    background-color: var(--color-white);
    border-left: 1px solid var(--light-color-gray-5);
    box-shadow: 0 1px 11px 0 rgba(34, 60, 80, .2);
  }
}

.popup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform .3s ease-out;
  transform: none;

  &_hidden {
    transform: translateY(100%);
  }

  &__text {
    flex-shrink: 0;
    line-height: 1.41;
    color: #989898;
  }

  &__close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    color: #989898;
    border-radius: 5px;
    transition: background-color .3s ease-out, color .3s ease-out;
  }

  &__container {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  &__link {
    display: inline-block;
    height: 45px;
    padding: 10px 40px;
    color: var(--color-black-10);
    text-decoration: none;
    background-color: var(--color-yellow-40);
    border: 1px solid var(--color-yellow-40);
    border-radius: 10px;

    &:hover {
      background-color: var(--color-yellow-10);
      border-color: var(--color-yellow-10);
    }
  }

  &__link_disabled {
    pointer-events: none;
    cursor: default;
    background-color: unset;
    border: solid 1px var(--light-gray);
    opacity: .6;
  }

  &__actions {
    display: flex;
    column-gap: 5px;
    align-items: center;
  }
}

.preprocessing {
  position: absolute;
  top: 0;
  right: 0;
  left: unset;
  width: calc(100% - 85px);
}
