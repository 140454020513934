.loader {
  position: fixed;
  inset: 0;
  z-index: 501;
  display: grid;
  place-content: center;
  background-color: #fffd;

  &__spinner-wrapper {
    display: flex;
    align-items: center;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.lds-spinner {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 60px;

  div {
    transform-origin: 39px 30px;
    animation: lds-spinner 1.2s linear infinite;

    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        transform: rotate(($i - 1) * 30deg); // 0, 30, 60...
        animation-delay: -1.2s + $i * 0.1s // -1.1, -1, -0.9
      }
    }

    &::after {
      position: absolute;
      top: 3px;
      left: 37px;
      display: block;
      width: 4px;
      height: 16px;
      content: ' ';
      background: #000;
      border-radius: 20%;
    }
  }
}

@keyframes lds-spinner {
  100% {
    opacity: 0;
  }
}
