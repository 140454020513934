.select-channel {
  --channel-select-bg-color: var(--theme-channel-select-bg-color, var(--color-white-20));

  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
  width: fit-content;

  &__control {
    width: 105px;
    min-height: 35px !important;
    border-radius: 50px !important;
  }

  &__dropdown {
    padding-right: 10px;
  }

  &__value-container {
    text-align: center;

    &_enabled {
      padding-left: 20px !important;
    }
  }

  &__placeholder {
    color: var(--color-black) !important;
  }
}

.control {
  background-color: var(--channel-select-bg-color) !important;
}
