.navigator-settings {
  position: relative;
  right: 0;
  height: 100%;

  &__wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--settings-width);
    overflow-y: auto;
  }

  &__row {
    position: relative;
    padding: 20px;
    font-size: 17px;

    &:not(:last-child)::before {
      position: absolute;
      top: 100%;
      right: 20px;
      left: 20px;
      content: '';
      border-bottom: 1px solid var(--separator-color);
    }
  }
}
