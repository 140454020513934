.statuses-data {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 6px;
  align-items: flex-end;
  width: fit-content;
  font-size: 14px;

  &__popover {
    min-width: fit-content;
    padding: 10px;
  }

  &__option {
    justify-content: flex-end;
    padding: 3px;

    &::before {
      border: none;
    }
  }

  &__all {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: flex-end;
    justify-self: flex-end;
    width: max-content;
    cursor: pointer;
  }

  &__count {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    font-size: 11px;
    color: var(--color-white);
    background-color: var(--color-black);
    border-radius: 20px;
  }
}
