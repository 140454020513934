.popover {
  &__container {
    z-index: var(--z-virtual-scroll-popover);
  }
}

.item {
  display: flex;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: var(--light-color-gray-5);
  }

  &_selected {
    background-color: var(--color-yellow-40);

    &:hover {
      background-color: var(--color-yellow);
    }
  }

  &__btn {
    --width: 150px;
    width: var(--width);
  }
}

.virtual-scroll {
  &__component {
    display: flex;
    flex-direction: column;
    max-height: 30dvh;
    overflow: auto;
  }
}

.scroll-area {
  &__wrapper {
    overflow: hidden;
    background-color: var(--color-white);
    border: 1px solid var(--color-white-20);
    border-radius: 10px;
  }
}

.select-input {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 150px;
  height: 40px;
  background-color: var(--color-white);
  border: 1px solid var(--light-color-gray-10);
  border-radius: 10px;


  &__input {
    all: unset;
    flex-grow: 1;
    padding-left: 10px;
    text-overflow: ellipsis;

    &::placeholder {
      font-size: 16px;
      color: var(--light-gray);
      opacity: 1;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
}
