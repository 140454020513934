.accordion {
  --accordion-button-padding: 26px 32px;

  list-style: none;
  // overflow: hidden; // if the content is hidden, the dropdowns are shown truncated
  background-color: var(--color-black);
  border-radius: 25px;

  &__button,
  &__content {
    border-radius: 25px;
  }

  &_light {
    background-color: var(--color-white);
  }

  &__head {
    width: 100%;
  }

  &__button {
    position: relative;
    width: 100%;
    padding: var(--accordion-button-padding);
    font-size: 30px;
    font-weight: normal;
    line-height: normal;
    color: var(--color-white);
    text-align: left;
    letter-spacing: -.4px;
    cursor: pointer;
    background-color: var(--color-black);
    border: none;
  }

  &_light &__button {
    color: var(--color-black);
    background-color: var(--color-white);
  }

  &__icon {
    position: absolute;
    top: 18px;
    right: 20px;
    width: 24px;
    height: 24px;
    color: var(--color-gray-10);
    transition: transform .3s;
  }

  &__content {
    grid-column-start: 2;
    background-color: var(--color-black);
  }

  &_light &__content {
    background: none;
  }

  .accordion__enter {
    max-height: 0;
    overflow: hidden;
  }

  .accordion__enter_active {
    max-height: 100vh;
    transition: max-height 300ms ease-in;
  }

  .accordion__exit {
    max-height: 100vh;
  }

  .accordion__exit_active {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease-out;
  }
}

.icon_opened {
  transform: rotate(-180deg);
}
