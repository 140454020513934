.textarea-wrapper {
  --textarea-bg-color: transparent;
  --textarea-bg-color-disabled: transparent;
  --textarea-border-color: var(--color-gray-70);
  --textarea-border-radius: 0;

  --textarea-color: var(--color-black);
  --textarea-color-disabled: var(--color-gray-10);

  --textarea-color-following-text: var(--color-gray-20);
  --textarea-color-following-text-disabled: var(--color-gray-60);

  --textarea-border-width: 1px;
  --textarea-padding: 5px 0;
  --textarea-font-size: 17px;

  --textarea-color-error: #e12727;

  position: relative;
  width: 100%;
  height: 100%;
}

.textarea {
  display: inline-block;
  width: 100%;
  padding: var(--textarea-padding);
  overflow-y: hidden;
  font-size: var(--textarea-font-size);
  resize: none;
  background-color: var(--textarea-bg-color);
  border: none;
  border-bottom: var(--textarea-border-width) solid var(--textarea-border-color);
  border-radius: var(--textarea-border-radius);

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    color: var(--textarea-color-disabled);
    cursor: not-allowed;
    background-color: var(--textarea-bg-color-disabled);
  }

  &_is-error {
    --textarea-border-color: var(--textarea-color-error);
  }

  &__error {
    color: var(--textarea-color-error);
  }
}
