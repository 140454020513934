.beads-settings {
  display: flex;
  column-gap: 70px;
  justify-content: flex-start;
}

.beads-setting {
  display: grid;
  grid-template-columns: 170px 1fr;
  align-items: center;
}

.beads-channel {
  grid-template-columns: 120px 1fr;
}

.beads-settings-select {
  width: fit-content;

  &__control {
    width: 105px;
    min-height: 35px !important;
    border-radius: 50px !important;
  }

  &__dropdown {
    padding-right: 10px;
  }

  &__value-container {
    padding-left: 20px !important;
    text-align: center;
  }

  &__placeholder {
    color: var(--color-black) !important;
  }
}

.beads-types {
  display: grid;
  grid-template-columns: 170px 1fr;

  &__selects {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: fit-content;
  }

  &__select-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    align-items: center;
  }
}

.beads-types-select {
  min-width: 350px;
  background-color: var(--color-white-20);
  border: 1px solid var(--light-color-gray-10);
  border-radius: 10px;

  &__value-container {
    padding-inline: 20px 5px !important;
  }

  &__control {
    min-height: 35px !important;
  }

}
