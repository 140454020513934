.search-input {
  display: grid;
  grid-template-columns: 1fr 45px;
  gap: 5px;
  margin-left: auto;
  cursor: default;
  border: solid 2px transparent;
  border-radius: 10px;
  transition: background-color .3s ease-out, border-color .3s ease-out, color .3s ease-out, opacity .3s ease-out;

  &_open {
    border-color: var(--light-color-gray-10);
  }

  &__input {
    pointer-events: none;
    opacity: 0;

    input {
      height: 100%;
      color: var(--color-black);
      border: none;
    }

    &_open {
      pointer-events: all;
      opacity: 1;
    }
  }

  &__button {
    width: 45px;
    padding: 0;
    color: var(--color-black);
  }
}
