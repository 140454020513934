.header {
  --border-bottom: 1px solid var(--light-color-gray-10);
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  line-height: 1.3;
  border-bottom: var(--border-bottom);

  &__title {
    font-size: 20px;

    &_bold {
      font-weight: bold;
    }
  }

  &__description {
    font-size: 15px;
  }

  &_no-border {
    --border-bottom: unset;
  }
}

.instructions {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px 0 15px 15px;

  &__item {
    position: relative;

    &::before {
      position: absolute;
      top: calc(45% - 5px);
      left: -15px;
      font-size: 10px;
      content: '•';
    }
  }
}
