.violin-popover {
  background-color: var(--color-white);
  border: 1px solid var(--icons-border-color);
  border-radius: 10px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid var(--icons-border-color);
  }
}

.violin-list {
  display: flex;
  flex-direction: column;
  max-height: 350px;
  padding-bottom: 5px;
  overflow: auto;
  border-bottom: 1px solid var(--icons-border-color);

  &_empty {
    padding-bottom: unset;
    border-bottom: unset;
  }
}

.violin-row {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 550px;
  height: 65px;
  padding: 10px;

  &__actions {
    display: flex;
    gap: 5px;
    // flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  &__data-wrapper {
    display: flex;
    flex-grow: 1;
    gap: 10px;
  }

}

.label {
  padding: 10px;
  border: 1px solid var(--icons-border-color);
  border-radius: 10px;
}

.gene-label {
  width: 250px;
}

.lane-label {
  flex-grow: 1;
}


.new-violin {
  padding-top: 5px;
}

.select {
  flex-grow: 1;
  border: solid 1px var(--icons-border-color);
  border-radius: 10px;

  &__virtual {
    width: 250px;

  }
}

.data-label {
  padding: 10px;
  border: 1px solid var(--icons-border-color);
  border-radius: 10px;
}

.icon {
  width: 16px;
  height: 16px;
}

.close-button {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 5px;
}

.load-status {
  --bg-color: var(--color-yellow);
  width: 10px;
  height: 10px;
  background-color: var(--bg-color);
  border-radius: 50%;

}

._loading {
  --bg-color: var(--color-yellow);
}

._empty {
  --bg-color: var(--light-gray);
}

._loaded {
  --bg-color: var(--color-green-10);
}

._error {
  --bg-color: var(--color-red);
}