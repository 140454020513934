.select-main {
  --select-bg-color: var(--theme-select-bg-color, var(--color-black-40));
  --select-border-color: var(--theme-select-border-color, var(--color-gray));
  --select-border-width: 2px;
  --select-color: var(--theme-select-color, #fff);
  --select-color-disabled: var(--theme-select-color-disabled, var(--color-gray-10));
  --select-bg-color-disabled: var(--theme-select-color-bg-disabled, var(--color-black-10));
  --select-font-size: var(--theme-select-font-size, 16px);

  --option-bg-color: #312d59;
  --option-color: #5d569d;
  --option-color-selected: var(--color-white);

  position: relative;

  &_disabled {
    cursor: not-allowed;
  }

  &_light {
    --select-border-width: 0;
  }
}

.select {
  & &__control {
    min-height: 40px;
    overflow: hidden;
    font-size: var(--select-font-size);
    cursor: pointer;
    background-color: var(--select-bg-color);
    border: var(--select-border-width) solid var(--select-border-color);
    border-radius: 10px;
    box-shadow: none;

    &:hover {
      border-color: var(--select-border-color);
    }
  }

  &_disabled &__control {
    background-color: var(--select-bg-color-disabled);

    & .select__dropdown-arrow {
      display: none;
    }
  }

  &_light.select_disabled &__control {
    background-color: unset;
    opacity: .7;
  }

  & &__clear-indicator {
    padding: 4px;
    color: var(--light-color-gray-10);
    border-radius: 4px;
    transition: all .3s ease-out;

    &:hover {
      background-color: var(--color-red-50);
    }
  }

  & &__dropdown-indicator {
    width: auto !important;
  }

  & &__indicator-separator {
    display: none;
  }

  & &__placeholder {
    overflow: hidden;
    font-size: var(--select-font-size);
    color: var(--color-white);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__placeholder-elements-container {
    display: flex;
    gap: 6px;
  }

  &_light &__placeholder {
    color: var(--light-gray);
  }

  &_disabled &__placeholder {
    color: var(--select-color-disabled);
  }

  & &__single-value {
    overflow: hidden;
    color: var(--color-white);
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
  }

  & &__single-value_hidden {
    display: none;
  }

  &_light &__single-value {
    color: var(--color-black);
  }

  &_disabled &__single-value {
    color: var(--select-color-disabled);
  }

  &__menu {
    --select-menu-margin-block: -40px -50px;

    &#{&} {
      --option-bg-color: var(--color-gray);
      --option-color: var(--color-gray-20);
      --option-color-focused: var(--color-white);
      --option-color-selected: var(--color-white);
      --option-separator-color: var(--color-gray-60);
      --option-padding-inline: 0;

      z-index: 2;
      width: max-content;
      min-width: 100%;
      max-width: 150%;
      font-size: var(--select-font-size);
      background-color: var(--option-bg-color);
      border-radius: 10px;
      box-shadow: none;
    }

    &_standard {
      &#{&} {
        margin-block: var(--select-menu-margin-block);
      }
    }

    &_right-align {
      &#{&} {
        right: 0;
      }
    }

    &_light {
      &#{&} {
        --option-color-selected: var(--color-black);
        --option-bg-color: var(--color-white-10);
        --option-color-focused: var(--color-black);
      }
    }
  }

  &__menu-list {
    &#{&} {
      text-align: left;
      background-color: var(--option-bg-color);
      border: none;
      border-radius: 10px;
      padding-inline: 12px;
      box-shadow: 0 0 0 1px hsla(0, 0%, 0%, .1), 0 4px 11px hsla(0, 0%, 0%, .1);
    }

    &_light {
      background-color: var(--color-white) !important;
    }
  }

  &__option-wrap {
    display: flex;
    align-items: center;
  }

  &__option {
    &#{&} {
      padding: 3px 3px 3px var(--option-padding-inline);
      overflow: hidden;
      color: var(--option-color);
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      background: none;
      border: none;

      &:active,
      &:hover {
        background-color: var(--color-white);
        opacity: .8;
      }

      &_multi-line {
        overflow: initial;
        text-overflow: initial;
        word-break: break-word;
        white-space: break-spaces;
        border-bottom: 1px solid var(--light-color-gray-10);

        &:last-child {
          border-bottom: none;
        }
      }
    }

    &_selected {
      font-weight: 500;
      color: var(--option-color-selected) !important;
    }

    &_focused {
      color: var(--option-color-focused) !important;
    }

    &_multiple {
      padding-left: 45px !important;
    }

    &_light-focus {
      color: var(--color-black) !important;
    }

    &_disabled {
      cursor: not-allowed !important;
    }
  }

  &__option_focused + &__checkbox {
    --checkbox-border-color: var(--option-color-focused);
  }

  &__title {
    position: relative;
    padding-top: 9px;
    padding-bottom: 12px;
    margin: 0 0 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid var(--option-separator-color);
  }

  &__checkbox {
    &#{&} {
      position: absolute;
      left: 13px;
      pointer-events: none;
    }
  }

  &__menu-portal {
    z-index: 1000 !important;
  }

  &__dropdown-arrow {
    margin-inline: 10px;
  }

  &_disabled &__dropdown-arrow {
    color: var(--color-gray-10);
  }

  &__show-title {
    position: relative;
    padding-top: 4px;
    padding-bottom: 7px;
    margin-bottom: 4px;
    font-size: var(--select-font-size);
    letter-spacing: .2px;
    border-bottom: 1px solid var(--option-separator-color);
  }

  &__title-arrow {
    position: absolute;
    top: calc(50% - 5px);
    right: -4px;
  }

  &__group-heading-wrap {
    --arrow-angle: 45deg;
    position: relative;

    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 8px;
      height: 8px;
      color: hsl(0, 0%, 60%);
      content: '';
      border-right: 2px solid currentcolor;
      border-bottom: 2px solid currentcolor;
      transform: translateY(-50%) rotate(var(--arrow-angle));
    }

    & > div {
      cursor: pointer;
    }

    &._is-open {
      --arrow-angle: 225deg;
    }
  }

  &__group-heading-wrap + div {
    display: none;
  }

  &__group-heading-wrap._is-open + div {
    display: block;
  }

  &__error {
    position: absolute;
    left: 0;
    font-size: 14px;
    color: var(--color-red-error);
  }
}
